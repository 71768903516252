import { gql } from "@apollo/client";

export const GET_SERVICE_CONSUMER_BY_EMAIL_OR_MOBILE = gql`
  query Query($consumer: ConsumerFilterInput!) {
    getConsumer(consumer: $consumer) {
      id
      firstName
      lastName
      email
      mobileNumber
      homePhoneNumber
      timeZone
      birthDate
      gender
      attributes
      organizationId
      isPatient
      addresses {
        id
        line1
        line2
        zipCode
        state
        district
        country
        city
      }
      idProof {
        idNumber
        state
        drivingLicenseFront {
          name
          cloudId
          cloudUrl
          mimeType
        }
        drivingLicenseBack {
          name
          cloudId
          cloudUrl
          mimeType
        }
      }
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        backSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        secondaryFrontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        secondaryBackSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        tertiaryFrontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        tertiaryBackSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorRelation
        guarantorBirthDate
      }
      dependants {
        id
        firstName
        lastName
        email
        mobileNumber
        homePhoneNumber
        timeZone
        gender
        birthDate
        isPatient
      }
      externalId
    }
  }
`;

export const CONSUMER_LOOK_UP = gql`
  query Query($consumer: ConsumerFilterInput!) {
    consumerLookUp(consumer: $consumer) {
      id
      mobileNumber
      organizationId
    }
  }
`;

export const GET_CONSUMER_GUARDIAN = gql`
  query Query($consumer: ConsumerFilterInput!) {
    consumerById(consumer: $consumer) {
      id
      firstName
      lastName
      email
      mobileNumber
      homePhoneNumber
      timeZone
      gender
      birthDate
      organizationId
      isPatient
      externalId
      addresses {
        id
        line1
        line2
        zipCode
        state
        district
        country
        city
      }
      dependants {
        id
        firstName
        lastName
        email
        gender
        birthDate
        mobileNumber
        homePhoneNumber
        timeZone
        organizationId
      }
    }
  }
`;
export const GET_CONSUMER_BLOCKED_SLOT = gql`
  query Query($blockedReferenceCode: String!) {
    getBlockedSlot(blockedReferenceCode: $blockedReferenceCode) {
      id
      date
      endTime
      startTime
      appointmentType {
        id
        name
        type
        description
        templateName
        organizationId
        durationInMinutes
        serviceProvider {
          id
          firstName
          lastName
          profilePic
        }
      }
    }
  }
`;

export const GET_AGREEMENT_TEMPLATE = gql`
  query Query($agreementTemplateFilter: AgreementTemplateFilterInput!) {
    getAgreementTemplate(agreementTemplateFilter: $agreementTemplateFilter) {
      id
      organizationId
      name
      contentHtml
      fields {
        key
        type
        required
      }
      createdAt
      updatedAt
    }
  }
`;

export const GET_CONSUMER_BY_ID = gql`
  query Query($consumer: ConsumerFilterInput!) {
    consumerById(consumer: $consumer) {
      id
      firstName
      lastName
      email
      mobileNumber
      homePhoneNumber
      timeZone
      birthDate
      gender
      attributes
      organizationId
      isPatient
      addresses {
        id
        line1
        line2
        zipCode
        state
        district
        country
        city
      }
      idProof {
        idNumber
        state
        drivingLicenseFront {
          name
          cloudId
          cloudUrl
          mimeType
        }
        drivingLicenseBack {
          name
          cloudId
          cloudUrl
          mimeType
        }
      }
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        backSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        secondaryFrontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        secondaryBackSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        tertiaryFrontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        tertiaryBackSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorRelation
        guarantorBirthDate
      }
      dependants {
        id
        firstName
        lastName
        email
        mobileNumber
        homePhoneNumber
        timeZone
        gender
        birthDate
        isPatient
      }
      externalId
    }
  }
`;

export const GET_GUEST_CONSUMER = gql`
  query Query($organizationId: Int!) {
    guestUser(organizationId: $organizationId) {
      id
      firstName
      lastName
      email
      gender
      birthDate
      mobileNumber
      homePhoneNumber
      timeZone
      organizationId
      isPatient
      idProof {
        idNumber
        state
        drivingLicenseFront {
          name
          cloudId
          cloudUrl
          mimeType
        }
        drivingLicenseBack {
          name
          cloudId
          cloudUrl
          mimeType
        }
      }
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        backSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        secondaryFrontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        secondaryBackSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        tertiaryFrontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        tertiaryBackSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorRelation
        guarantorBirthDate
      }
      dependants {
        id
        firstName
        lastName
        email
        gender
        birthDate
        mobileNumber
        homePhoneNumber
        timeZone
        organizationId
        isPatient
      }
    }
  }
`;

export const CONSUMER_PAYMENT_SOURCES = gql`
  query Query {
    getCustomerPaymentSources {
      id
      object
      type
      name
      email
      phone
      customer
      card {
        brand
        cvc_check
        country
        exp_month
        exp_year
        fingerprint
        funding
        generated_from
        last4
      }
      created
      address {
        zipCode
        state
      }
    }
  }
`;
