import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const StepSuccess = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className=" overflow-hidden flex flex-col justify-start mt-40 items-center gap-y-2 px-4">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-20 h-20"
        viewBox="0 0 64 64"
        enableBackground="new 0 0 64 64">
        <path
          d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50  l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z"
          fill="#43a047"
        />
      </svg>

      <h1>Thank You.</h1>
      <p className="text-center">
        You will receive a copy of your agreement shortly.
      </p>
      <button
        className="w-max border bg-primary px-8 py-1 text-white rounded font-bold"
        onClick={() => navigate("/logout")}>
        Done
      </button>
    </div>
  );
};

export default StepSuccess;
