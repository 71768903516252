import { useMutation } from "@apollo/client";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import * as Yup from "yup";
import { UPDATE_CONSUMER_GUARDIAN } from "../../apollo";
import {
  AdditionalFormFieldsProps,
  IAddressResponse,
  ICities,
  IServiceConsumer,
  IStateResponse,
} from "../../interfaces";
import { loggerService, networkService } from "../../services";
import websocketLoggerService from "../../services/websocket-logger";
import useFormStore from "../../store/form/form.store";
// import DateSeparateInput from "../shared/components/date-separate-input.component";
import FormikInput from "../shared/components/formik-input.component";
import {
  PatientFlowType,
  ScopeType,
  // regexConstants,
  urls,
  usStates,
} from "../shared/config";
// import { useServiceConsumer } from "../shared/hooks";
// import { parseDOB, parseISODateStr } from "../shared/utils/helper.util";

//  commented part is responsible party section, we are removing it for now, will enable it in the future
// Affected files are additional-details.component.tsx and pi-guardian-info-form.component.tsx

const additionalFormSchema = Yup.object().shape({
  addressFirstLine: Yup.string(),
  addressSecondLine: Yup.string(),
  zipCode: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  county: Yup.string(),
  country: Yup.string(),
  // responsibleParty: Yup.string(),
  // relationship: Yup.string().when("responsibleParty", {
  //   is: "Another guarantor",
  //   then: Yup.string()
  //     .required("Guarantor Relationship to Patient is required")
  //     .nullable(),
  //   otherwise: Yup.string(),
  // }),
  // firstName: Yup.string().when("responsibleParty", {
  //   is: "Another guarantor",
  //   then: Yup.string().required("Guarantor First Name is required").nullable(),
  //   otherwise: Yup.string().nullable(),
  // }),
  // lastName: Yup.string().when("responsibleParty", {
  //   is: "Another guarantor",
  //   then: Yup.string().required("Guarantor Last Name is required").nullable(),
  //   otherwise: Yup.string().nullable(),
  // }),
  // mobilePhoneNumber: Yup.string().when("responsibleParty", {
  //   is: "Another guarantor",
  //   then: Yup.string()
  //     .required("Guarantor Mobile Phone Number is required")
  //     .matches(
  //       regexConstants.phoneRegExp,
  //       "Please enter a valid Mobile Phone Number"
  //     )
  //     .nullable(),
  //   otherwise: Yup.string().nullable(),
  // }),
  // dependantYourselfRelationship: Yup.string().nullable(),
  // birthDate: Yup.date().when("responsibleParty", {
  //   is: "Another guarantor",
  //   then: Yup.date()
  //     .typeError("Invalid Date")
  //     .nullable()
  //     .required("Guarantor Date of Birth is required")
  //     .nullable(),
  //   otherwise: Yup.date().typeError("Invalid Date").nullable(),
  // }),
});

const AdditionalFormFields: React.FC<AdditionalFormFieldsProps> = ({
  setStep,
  NextBtn,
}) => {
  const [patientFlow, currentFlowPatient, setCurrentFlowPatient] = useFormStore(
    (store) => [
      store.patientFlow,
      store.currentFlowPatient,
      store.setCurrentFlowPatient,
    ]
  );

  // const serviceConsumer: any = useServiceConsumer();

  const [formattedAddress, setFormattedAddress] = useState<string>(null);
  const [invalidFields, setInvalidFields] = useState<any>([]);

  // const [guardianDetails, setGuardianDetails] = useState(null);
  const [isAutoCompleted, setIsAutoCompleted] = useState(false);
  const [states, setStates] = useState<string[]>([]);
  const [cities, setCities] = useState<ICities[]>([]);

  const [updateConsumerGuardian] = useMutation<{
    updateConsumer: IServiceConsumer;
  }>(UPDATE_CONSUMER_GUARDIAN);

  useEffect(() => {
    const setAddress = async () => {
      try {
        if (
          (currentFlowPatient?.addresses &&
            currentFlowPatient?.addresses[0]?.city) ||
          currentFlowPatient?.addresses[0]?.state ||
          currentFlowPatient?.addresses[0]?.zipCode ||
          currentFlowPatient?.addresses[0]?.line1 ||
          currentFlowPatient?.addresses[0]?.line2 ||
          currentFlowPatient?.addresses[0]?.country ||
          currentFlowPatient?.addresses[0]?.county
        ) {
          setIsAutoCompleted(true);
        }

        if (currentFlowPatient?.addresses[0]?.city) {
          handleChange({
            country: currentFlowPatient?.addresses[0]?.country,
            state: currentFlowPatient?.addresses[0]?.state,
          });
        }
      } catch (error) {
        loggerService.log(error);
      }
    };

    if (currentFlowPatient?.id) {
      setAddress();
    }
  }, [currentFlowPatient?.id, currentFlowPatient?.addresses]);

  // useEffect(() => {
  //   if (patientFlow !== PatientFlowType.Dependant) {
  //     return;
  //   }
  //   const getData = async () => {
  //     try {
  //       const { data: guardianData } = await getConsumerGuardian({
  //         variables: {
  //           consumer: {
  //             consumerId: serviceConsumer?.id,
  //             organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
  //           },
  //         },
  //       });
  //       const response = helper.getPropertyValue<IConsumerGuardian>(
  //         guardianData,
  //         "consumerById"
  //       );

  //       websocketLoggerService.sendMessage({
  //         eventType: "ADDITIONAL_DETAILS",
  //         eventSubType: "API Request: getConsumerGuardian success",
  //         eventData: `ConsumerId : ${
  //           serviceConsumer?.id as string
  //         } , OrganizationId : ${process.env.REACT_APP_ORGANIZATION_ID}`,
  //       });

  //       setGuardianDetails(response);
  //     } catch (error) {
  //       websocketLoggerService.sendMessage({
  //         eventType: "ADDITIONAL_DETAILS",
  //         eventSubType: "Error: getConsumerGuardian failed",
  //         eventData: `Error : ${JSON.stringify(error)}`,
  //       });
  //       loggerService.log(error);
  //     }
  //   };

  //   // if (currentFlowPatient.id) {
  //   getData();
  //   // }
  // }, []);

  useEffect(() => {
    try {
      // TODO country dropdown disabled for now and pre-selected to United States so dont need to fetch all countries
      // const fetchCountries = async () => {
      //   const data = await networkService.get<ICountries[]>(
      //     `${process.env.REACT_APP_LOCATION_SERVICE_URL}/countries`
      //   );
      //   setCountries(data);
      // };

      const fetchStates = async () => {
        const data = await networkService.get<IStateResponse>(
          `${process.env.REACT_APP_LOCATION_SERVICE_URL}/states/United%20States`
        );
        setStates(data.states);
      };
      // fetchCountries();
      fetchStates();
    } catch (error) {
      websocketLoggerService.sendMessage({
        eventType: "ADDITIONAL_DETAILS_FETCH_STATES_COUNTRIES",
        eventSubType: "Error: fetchStates,fetchCountries failed",
        eventData: `Error : ${JSON.stringify(error)}`,
      });
      loggerService.error("Address", error);
    }
  }, []);

  // const isObjectEmpty = (objectName) => {
  //   return (
  //     objectName &&
  //     Object.keys(objectName).length === 0 &&
  //     objectName.constructor === Object
  //   );
  // };

  const handleAdditionalDetailsSubmit = async (values) => {
    const isUnitedStates = values.country === "United States";
    const isEmpty = Object.values(values).every((x) => x === null || x === "");

    // if (
    //   values.responsibleParty === "Yourself" &&
    //   patientFlow === PatientFlowType.Dependant
    // ) {
    //   setguardianRelationship(values.dependantYourselfRelationship);
    // }
    if (
      (isEmpty && isUnitedStates) ||
      (!isAutoCompleted && !formattedAddress)
      // && values.responsibleParty.length === 0
    ) {
      setStep((prev: number) => prev + 1);
      return;
    }

    try {
      // const insuranceDetailsPayload = {
      //   ...(currentFlowPatient?.insuranceDetails?.frontSide && {
      //     frontSide: currentFlowPatient?.insuranceDetails?.frontSide,
      //   }),
      //   ...(currentFlowPatient?.insuranceDetails?.backSide && {
      //     backSide: currentFlowPatient?.insuranceDetails?.backSide,
      //   }),
      //   guarantorBirthDate:
      //     values.responsibleParty === "Yourself" ||
      //     (values.responsibleParty === "PatientRelationship" &&
      //       patientFlow === PatientFlowType.Dependant)
      //       ? currentFlowPatient?.birthDate
      //       : parseISODateStr(values.birthDate),
      //   guarantorFirstName:
      //     values.responsibleParty === "Yourself" ||
      //     (values.responsibleParty === "PatientRelationship" &&
      //       patientFlow === PatientFlowType.Dependant)
      //       ? currentFlowPatient?.firstName
      //       : values.firstName,
      //   guarantorLastName:
      //     values.responsibleParty === "Yourself" ||
      //     (values.responsibleParty === "PatientRelationship" &&
      //       patientFlow === PatientFlowType.Dependant)
      //       ? currentFlowPatient?.lastName
      //       : values.lastName,
      //   guarantorPhone:
      //     values.responsibleParty === "Yourself" ||
      //     (values.responsibleParty === "PatientRelationship" &&
      //       patientFlow === PatientFlowType.Dependant)
      //       ? currentFlowPatient?.mobileNumber
      //       : values.mobilePhoneNumber !== ""
      //       ? `+1${values.mobilePhoneNumber as string}`
      //       : "",
      //   guarantorRelation:
      //     values.responsibleParty === "Yourself" ||
      //     (values.responsibleParty === "PatientRelationship" &&
      //       patientFlow === PatientFlowType.Dependant)
      //       ? selfRelation
      //       : values.relationship,
      // };

      // const filteredInsuranceDetailsPayload = Object.fromEntries(
      //   Object.entries(insuranceDetailsPayload).filter(
      //     ([_, value]) => value !== undefined && value !== null && value !== ""
      //   )
      // );

      const addressPayload = [
        {
          ...(currentFlowPatient?.addresses &&
            currentFlowPatient?.addresses[0]?.id && {
              id: currentFlowPatient?.addresses[0]?.id,
            }),
          line1: values.addressFirstLine,
          line2: values.addressSecondLine,
          state: values.state,
          zipCode: values.zipCode,
          city: values.city,
          district: values.county,
          country: "United States",
        },
      ];

      const payload = {
        firstName: currentFlowPatient?.firstName,
        lastName: currentFlowPatient?.lastName,
        organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
        email: currentFlowPatient?.email,
        gender: currentFlowPatient?.gender,
        mobileNumber: currentFlowPatient?.mobileNumber,
        timeZone: currentFlowPatient?.timeZone,
        id: currentFlowPatient?.id,

        birthDate: currentFlowPatient?.birthDate,

        ...(values.addressFirstLine !== "" && { addresses: addressPayload }),
        // ...((patientFlow !== PatientFlowType.Dependant ||
        //   values.responsibleParty !== "Yourself") &&
        //   !isObjectEmpty(filteredInsuranceDetailsPayload) && {
        //     insuranceDetails: filteredInsuranceDetailsPayload,
        //   }),
      };

      const updatedUser = await updateConsumerGuardian({
        variables: {
          consumer: payload,
        },
        context: {
          scope: ScopeType.Consumer,
        },
      });

      setCurrentFlowPatient({
        ...currentFlowPatient,
        ...updatedUser.data.updateConsumer,
      });

      websocketLoggerService.sendMessage({
        eventType: "ADDITIONAL_DETAILS",
        eventSubType: "API Request: handleAdditionalDetailsSubmit",
        eventData: `ConsumerId : ${
          currentFlowPatient.id as unknown as string
        } , OrganizationId : ${process.env.REACT_APP_ORGANIZATION_ID}`,
      });
      setStep((prev: number) => prev + 1);
    } catch (error) {
      websocketLoggerService.sendMessage({
        eventType: "ADDITIONAL_DETAILS",
        eventSubType: "Error: handleAdditionalDetailsSubmit failed",
        eventData: `Error : ${JSON.stringify(error)}`,
      });
      loggerService.log(error);
    }
  };

  const handleVerifyAddress = async (values) => {
    try {
      const addressLines = [
        values.addressFirstLine,
        values.addressSecondLine,
        values.city,
        values.county,
        values.state,
        values.country,
        values.zipCode,
      ];
      // const addressRegion = [
      //   values.city,
      //   values.county,
      //   values.state,
      //   values.country,
      //   values.zipCode,
      // ];

      const addressRequestBody = {
        regionCode: "US",
        postalCode: values.zipCode,
        administrativeArea: `${values.state as string}`,
        locality: values.city,
        addressLines: [addressLines.join(",")],
      };

      const response = await networkService.post<IAddressResponse>(
        urls.googleMapsVerifyAddress,
        { address: addressRequestBody }
      );

      websocketLoggerService.sendMessage({
        eventType: "ADDRESS_VERIFICATION request success",
        eventSubType: `Success: handleVerifyAddress success, Address used: ${JSON.stringify(
          addressRequestBody
        )} `,
        eventData: `Error: ${JSON.stringify(addressRequestBody)}`,
      });

      const getStateAbbreviation = (stateName) => {
        const matchingState = usStates.find(
          (state) => state.name.toUpperCase() === stateName.toUpperCase()
        );
        return matchingState ? matchingState.abbreviation : "";
      };

      const addressValues = {
        "Address First Line": values.addressFirstLine,
        "Address Second Line": values.addressSecondLine,
        "Zip Code": values.zipCode,
        City: values.city,
        County: values.county,
        State: values.state,
        StateAbbreviation: getStateAbbreviation(values.state), // check for state abbreviation as well
        Country: values.country,
      };

      const componentTypeMappings = {
        locality: "City",
        administrative_area_level_2: "County",
        administrative_area_level_1: "State",
        country: "Country",
        postal_code: "Zip Code",
        street_number: "Address First Line",
        route: "Address First Line",
        neighborhood: "Address First Line",
      };

      const replacedFields = response.result.address.addressComponents
        .filter(
          (item) => item.confirmationLevel === "CONFIRMED" && item?.replaced
        )
        .map(
          (item) => addressValues[componentTypeMappings[item.componentType]]
        );
      const invalidFieldRes = response.result.address.addressComponents
        .filter((item) =>
          [
            "CONFIRMATION_LEVEL_UNSPECIFIED",
            "UNCONFIRMED_AND_SUSPICIOUS",
            "UNCONFIRMED_BUT_PLAUSIBLE",
          ].includes(item.confirmationLevel)
        )
        .map((item) => item.componentName.text);

      let invalidFields = [];

      const invalidSubstrings = invalidFieldRes
        .concat(replacedFields)
        .flatMap((invalidStr) =>
          invalidStr.split(" ").filter((substr) => substr.trim() !== "")
        );

      for (const key in addressValues) {
        if (Object.prototype.hasOwnProperty.call(addressValues, key)) {
          const value = addressValues[key];

          if (
            invalidSubstrings.some((invalidStr) => value.includes(invalidStr))
          ) {
            const isUnconfirmedPlausible =
              (key === "Address Second Line" || key === "County") &&
              response.result.address.addressComponents.some(
                (component) =>
                  component.componentName.text === value.trim() &&
                  component.confirmationLevel === "UNCONFIRMED_BUT_PLAUSIBLE"
              );

            if (!isUnconfirmedPlausible) {
              invalidFields.push(key);
            }
          }
        }
      }

      invalidFields = invalidFields.map((item) =>
        item === "StateAbbreviation" ? "State" : item
      );

      if (invalidFields.length > 0) {
        websocketLoggerService.sendMessage({
          eventType: "INVALID_ADDRESS_FIELDS FOUND",
          eventSubType: `Error: handleVerifyAddress failed, Address used: ${JSON.stringify(
            addressValues
          )} `,
          eventData: `Error: ${invalidFields?.toString()}`,
        });
      }

      setInvalidFields(invalidFields);
      setFormattedAddress(response.result.address.formattedAddress);
    } catch (error) {
      websocketLoggerService.sendMessage({
        eventType: "VERIFY_ADDRESS",
        eventSubType: "Error: handleVerifyAddress failed",
        eventData: `Error : ${JSON.stringify(error)}`,
      });
      loggerService.error("Address", error);
    }
  };

  function getLongNameByType(data, type): string {
    for (let i = 0; i < data.length; i++) {
      if (data[i].types.includes(type)) {
        return data[i].long_name;
      }
    }
    return "";
  }

  const handleChange = async (values) => {
    try {
      if (values.country && !values.state) {
        const fetchStates = async () => {
          const data = await networkService.get<IStateResponse>(
            `${process.env.REACT_APP_LOCATION_SERVICE_URL}/states/${
              values.country as string
            }`
          );
          setStates(data.states);
          setCities([]);
        };
        fetchStates();
      } else if (values.state) {
        const fetchCities = async () => {
          const data = await networkService.get<ICities[]>(
            `${
              process.env.REACT_APP_LOCATION_SERVICE_URL
            }/cities/United%20States/${values.state as string}`
          );
          setCities(data);
        };
        fetchCities();
      }
    } catch (error) {
      websocketLoggerService.sendMessage({
        eventType: "ADDITIONAL_DETAILS_FETCH_STATES_CITIES",
        eventSubType: "Error: handleChange failed",
        eventData: `Error : ${JSON.stringify(error)}`,
      });
      loggerService.error("Address", error);
    }
  };

  const handleFieldChange = (e, valueToChange, setFieldValue) => {
    setIsAutoCompleted(false);
    setFormattedAddress(null);
    setInvalidFields(null);
    setFieldValue(valueToChange, e.target.value);
  };

  // const relationships = [
  //   "Spouse - patient is the spouse of the insured",
  //   "Natural Child - Insured has Financial Responsibility",
  //   "Natural Child - Insured does not have Financial Responsibility (includes legally adopted child)",
  //   "Step Child",
  //   "Foster Child",
  //   "Ward of the Court",
  //   "Employee",
  //   "Unknown - patient's relationship to the insured is other than any listed",
  //   "Handicapped Dependent",
  //   "Organ Donor",
  //   "Cadaver Donor",
  //   "Grandchild",
  //   "Niece/Nephew",
  //   "Injured Plaintiff",
  //   "Sponsored Dependent",
  //   "Minor Dependent of a Minor Dependent",
  //   "Parent",
  //   "Grandparent",
  //   "Life Partner",
  //   "Other",
  // ];

  // const selfRelation = "Self - patient is the insured";

  const patientAddress =
    (currentFlowPatient?.addresses && currentFlowPatient?.addresses[0]) || {};
  // const insuranceDetails = currentFlowPatient?.insuranceDetails || {};
  // const guardianMatchesGuarantor =
  //   guardianDetails?.firstName === insuranceDetails?.guarantorFirstName &&
  //   guardianDetails?.lastName === insuranceDetails?.guarantorLastName &&
  //   guardianDetails?.mobileNumber === insuranceDetails?.guarantorPhone;

  const formikInitialValues = {
    addressFirstLine: patientAddress.line1 || "",
    addressSecondLine: patientAddress.line2 || "",
    zipCode: patientAddress.zipCode || "",
    city: patientAddress.city || "",
    state: patientAddress.state || "",
    county: patientAddress.district || "",
    country: "United States",

    // responsibleParty: guardianMatchesGuarantor
    //   ? "Yourself"
    //   : insuranceDetails.guarantorRelation === selfRelation
    //   ? patientFlow === PatientFlowType.Yourself
    //     ? "Yourself"
    //     : "PatientRelationship"
    //   : insuranceDetails.guarantorFirstName ||
    //     insuranceDetails.guarantorLastName ||
    //     insuranceDetails.guarantorPhone ||
    //     insuranceDetails.guarantorRelation
    //   ? "Another guarantor"
    //   : "",
    // birthDate:
    //   (insuranceDetails.guarantorRelation !== selfRelation ||
    //     insuranceDetails.guarantorBirthDate !== currentFlowPatient?.birthDate) &&
    //   insuranceDetails.guarantorBirthDate !== guardianDetails?.birthDate
    //     ? parseDOB(insuranceDetails.guarantorBirthDate)
    //     : "",
    // relationship:
    //   insuranceDetails.guarantorRelation === selfRelation ||
    //   guardianMatchesGuarantor
    //     ? ""
    //     : insuranceDetails.guarantorRelation || "",

    // firstName:
    //   (insuranceDetails.guarantorRelation !== selfRelation ||
    //     insuranceDetails.guarantorFirstName !== currentFlowPatient?.firstName) &&
    //   !guardianMatchesGuarantor
    //     ? insuranceDetails.guarantorFirstName
    //     : "",

    // lastName:
    //   (insuranceDetails.guarantorRelation !== selfRelation ||
    //     insuranceDetails.guarantorLastName !== currentFlowPatient?.lastName) &&
    //   !guardianMatchesGuarantor
    //     ? insuranceDetails.guarantorLastName
    //     : "",

    // mobilePhoneNumber:
    //   (insuranceDetails.guarantorRelation !== selfRelation ||
    //     insuranceDetails.guarantorPhone !== currentFlowPatient?.mobileNumber) &&
    //   !guardianMatchesGuarantor
    //     ? insuranceDetails?.guarantorPhone
    //       ? insuranceDetails?.guarantorPhone?.slice(2)
    //       : ""
    //     : "",

    // dependantYourselfRelationship: guardianMatchesGuarantor
    //   ? insuranceDetails.guarantorRelation
    //   : "",
  };

  return (
    <div className="w-3/4 max-lg:w-full">
      <div className="border border-gray-200 p-4 rounded-md mb-8 flex items-center justify-between gap-2">
        <div className="flex items-center gap-4 text-lg">
          <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="9.5" cy="10.2206" r="9.5" fill="#34A853" />
            <path
              d="M4.46875 10.7233L7.66928 13.8527L14.5276 7.14685"
              stroke="white"
              strokeWidth="1.5"
            />
          </svg>

          <p className="flex justify-between ">
            {currentFlowPatient?.firstName} {currentFlowPatient?.lastName}
          </p>
        </div>
        <span className="text-gray-400">Patient</span>
      </div>
      <div className="max-lg:mb-32 max-lg:px-4">
        <h1 className="font-bold text-3xl">Additional Details</h1>

        <section>
          <Formik
            validationSchema={additionalFormSchema}
            enableReinitialize={true}
            initialValues={formikInitialValues}
            onSubmit={(values) => {
              handleAdditionalDetailsSubmit(values);
            }}>
            {({ values, setFieldValue, dirty, setTouched, validateForm }) => (
              <Form className="flex gap-3 flex-col">
                <div className="flex justify-between w-full mt-4">
                  <span className="font-bold text-lg">
                    {" "}
                    Look Up Patient's Address
                  </span>
                  <span className="text-sm">Optional</span>
                </div>
                <div className="relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="19"
                    fill="none"
                    className="absolute top-4 left-2"
                    viewBox="0 0 18 19">
                    <g clipPath="url(#clip0_2578_10614)">
                      <path
                        fill="#000"
                        d="M13.773 13.523L9 18.296l-4.773-4.773a6.75 6.75 0 119.546 0zM9 10.25a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_2578_10614">
                        <path
                          fill="#fff"
                          d="M0 0H18V18H0z"
                          transform="translate(0 .5)"></path>
                      </clipPath>
                    </defs>
                  </svg>
                  <Autocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                    className="w-full pl-8 py-3 pr-2 border border-gray-300 rounded-md  focus:border-primary focus:outline-1 active:outline-1 focus-within:outline-0 focus-visible:outline-none focus:text-primary disabled:bg-gray-200"
                    placeholder="Enter full Address"
                    onKeyDown={(e) => {
                      e.key === "Enter" && e.preventDefault();
                    }}
                    onChange={() => {
                      setInvalidFields(null);
                      setIsAutoCompleted(false);
                      setFormattedAddress(null);
                      setFieldValue("addressFirstLine", "");
                      setFieldValue("addressSecondLine", "");
                      // setFieldValue("country", "");
                      setFieldValue("zipCode", "");
                      setFieldValue("state", "");
                      setFieldValue("city", "");
                      setFieldValue("county", "");
                    }}
                    onPlaceSelected={(place) => {
                      setIsAutoCompleted(true);
                      setFieldValue(
                        "addressFirstLine",
                        `${getLongNameByType(
                          place.address_components,
                          "street_number"
                        )} ${getLongNameByType(
                          place.address_components,
                          "route"
                        )} ${getLongNameByType(
                          place.address_components,
                          "neighborhood"
                        )}`.trim()
                      );

                      // setFieldValue(
                      //   "country",
                      //   getLongNameByType(place.address_components, "country")
                      // );

                      setFieldValue(
                        "zipCode",
                        getLongNameByType(
                          place.address_components,
                          "postal_code"
                        )
                      );

                      setFieldValue(
                        "state",
                        getLongNameByType(
                          place.address_components,
                          "administrative_area_level_1"
                        )
                      );
                      handleChange({
                        country: getLongNameByType(
                          place.address_components,
                          "country"
                        ),
                        state: getLongNameByType(
                          place.address_components,
                          "administrative_area_level_1"
                        ),
                      });

                      setFieldValue(
                        "city",
                        getLongNameByType(place.address_components, "locality")
                      );

                      setFieldValue(
                        "county",
                        getLongNameByType(
                          place.address_components,
                          "administrative_area_level_2"
                        )
                      );
                    }}
                    options={{
                      // types: ["address", "street_number"],
                      types: [
                        "street_number",
                        "street_address",
                        "locality",
                        "postal_code",
                        "administrative_area_level_1",
                      ],
                      fields: ["address_components"],
                      componentRestrictions: { country: "us" },
                    }}
                  />
                </div>
                <div className="relative flex py-2 items-center">
                  <div className="flex-grow border-t border-[#D5D5D5]"></div>
                  <span className="flex-shrink mx-4 text-black">OR</span>
                  <div className="flex-grow border-t border-[#D5D5D5]"></div>
                </div>

                <div className="flex justify-between w-full py-4">
                  <span className="font-bold text-lg">
                    Enter Patient's Address Manually
                  </span>
                  <span className="text-sm">Optional</span>
                </div>

                <FormikInput
                  name="addressFirstLine"
                  placeholder="Address here"
                  type="text"
                  label="Address First Line"
                  onChange={(e) => {
                    handleFieldChange(e, "addressFirstLine", setFieldValue);
                  }}
                  value={values.addressFirstLine}
                />
                <FormikInput
                  name="addressSecondLine"
                  placeholder="Address here"
                  type="text"
                  label="Address Second Line"
                  onChange={(e) => {
                    handleFieldChange(e, "addressSecondLine", setFieldValue);
                  }}
                  value={values.addressSecondLine}
                />

                <FormikInput
                  name="country"
                  placeholder="Enter Country"
                  type="text"
                  label="Country"
                  disabled={true}
                  onChange={(e) => {
                    handleFieldChange(e, "country", setFieldValue);
                    setFieldValue("state", "");
                    setFieldValue("city", "");
                    handleChange({ country: e.target.value });
                  }}
                  value={values.country}
                  component="select">
                  <option value={values.country}>United States</option>
                </FormikInput>

                <div className="flex gap-6 items-start max-sm:flex-col max-sm:w-full max-sm:gap-3">
                  <FormikInput
                    name="zipCode"
                    placeholder="Enter Zip Code"
                    inputMode="numeric"
                    onChange={(e) => {
                      handleFieldChange(e, "zipCode", setFieldValue);
                    }}
                    type="text"
                    pattern="\d*"
                    maxLength="5"
                    label="Zip Code"
                    component="input"
                    value={values.zipCode}
                  />

                  <FormikInput
                    name="state"
                    placeholder="Enter State"
                    type="text"
                    label="State"
                    onChange={(e) => {
                      handleFieldChange(e, "state", setFieldValue);
                      handleChange({
                        state: e.target.value,
                        country: values.country,
                      });
                      setFieldValue("city", "");
                    }}
                    value={values.state}
                    component="select">
                    <option className="text-gray-400" value="" disabled>
                      Select State
                    </option>
                    {states &&
                      states.length > 0 &&
                      states.map((state, index) => {
                        return (
                          <option key={index} value={state}>
                            {state}
                          </option>
                        );
                      })}
                  </FormikInput>
                </div>

                <div className="flex gap-6 items-start max-sm:flex-col max-sm:w-full max-sm:gap-3">
                  <FormikInput
                    name="city"
                    placeholder="Enter City"
                    type="text"
                    label="City"
                    onChange={(e) => {
                      handleFieldChange(e, "city", setFieldValue);
                    }}
                    value={values.city}
                    component="select">
                    <option className="text-gray-400" value="" disabled>
                      Select City
                    </option>
                    {cities
                      ? cities.map((city, index) => {
                          return (
                            <option key={index} value={city.name}>
                              {city.name}
                            </option>
                          );
                        })
                      : null}
                  </FormikInput>

                  <FormikInput
                    name="county"
                    placeholder="Enter County"
                    type="text"
                    onChange={(e) => {
                      handleFieldChange(e, "county", setFieldValue);
                    }}
                    label="County"
                    value={values.county}
                    component="input"></FormikInput>
                </div>

                {invalidFields?.length > 0 ? (
                  <div className="flex  items-center rounded gap-2 mt-2 bg-[#FFE9F0] p-2 py-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="18"
                      fill="none"
                      viewBox="0 0 20 18">
                      <path
                        fill="#E1002D"
                        d="M19.859 8.241L15.443.593C15.255.267 14.793 0 14.416 0H5.585c-.377 0-.84.267-1.028.593L.141 8.241c-.188.327-.188.86 0 1.187l4.416 7.648c.188.326.65.593 1.028.593h8.83c.377 0 .84-.267 1.028-.593l4.416-7.648c.188-.327.188-.86 0-1.187zm-1.069.62l-4.371 7.573a.277.277 0 01-.047.027H5.628a.27.27 0 01-.047-.027L1.21 8.862a.26.26 0 010-.055L5.58 1.235a.288.288 0 01.047-.027h8.744a.277.277 0 01.046.027l4.372 7.572a.246.246 0 010 .055z"></path>
                      <path
                        fill="#E1002D"
                        d="M10.575 10.829l.237-8.138H9.188l.236 8.138h1.15zM10.015 12.182c-.592 0-1.015.44-1.015 1.049 0 .592.406 1.049.981 1.049.626 0 1.015-.457 1.015-1.05 0-.608-.389-1.048-.98-1.048z"></path>
                    </svg>
                    <div className="text-sm text-[#E1002D]">
                      Following fields seems either invalid or missing:{" "}
                      {invalidFields?.map((field, index) => {
                        const isLast = index === invalidFields.length - 1;

                        return (
                          <span
                            key={index}
                            className="text-sm mr-1 font-semibold text-[#E1002D]">
                            {field}
                            {!isLast && ","}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  formattedAddress && (
                    <div className="mt-4">
                      <div className="flex items-center rounded bg-green-50 p-2 py-4 gap-2 mt-2">
                        <svg
                          width="19"
                          height="20"
                          viewBox="0 0 19 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <circle
                            cx="9.5"
                            cy="10.2206"
                            r="9.5"
                            fill="#34A853"
                          />
                          <path
                            d="M4.46875 10.7233L7.66928 13.8527L14.5276 7.14685"
                            stroke="white"
                            strokeWidth="1.5"
                          />
                        </svg>
                        <p className=" text-black">
                          <b>Verified:</b> {formattedAddress.replace("_", " ")}
                        </p>
                      </div>
                      <div className="flex items-center rounded bg-yellow-50 p-2 py-4 gap-2 mt-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-8 h-8">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                          />
                        </svg>

                        <p className=" text-black">
                          If the above verified address does not reflect your
                          correct address please modify the details before
                          proceeding.
                        </p>
                      </div>
                    </div>
                  )
                )}
                {!isAutoCompleted && !formattedAddress && (
                  <div>
                    <button
                      type="button"
                      disabled={
                        !values.zipCode ||
                        !values.state ||
                        !values.country ||
                        !values.county ||
                        !values.addressFirstLine ||
                        (cities.length !== 0 && !values.city) ||
                        (states.length !== 0 && !values.state)
                      }
                      onClick={async () => await handleVerifyAddress(values)}
                      className={`w-full bg-primary max-w-fit px-10 py-4 my-4 text-white rounded font-bold disabled:bg-gray-400 disabled:cursor-not-allowed max-lg:px-16`}>
                      Verify Patient Address
                    </button>
                    {/* <hr /> */}
                  </div>
                )}

                {/* <div></div>
                <div className="flex justify-between w-full mb-4 mt-2 ">
                  <span className="font-bold text-lg">Responsible Party</span>
                  <span className="text-sm">Optional</span>
                </div>
                <div className="flex justify-between items-center">
                  <div>Who is Responsible Party?</div>
                  {values.responsibleParty.length > 0 &&
                    (!currentFlowPatient?.insuranceDetails?.guarantorRelation ||
                      !currentFlowPatient?.insuranceDetails?.guarantorFirstName ||
                      !currentFlowPatient?.insuranceDetails?.guarantorLastName ||
                      !currentFlowPatient?.insuranceDetails?.guarantorBirthDate ||
                      !currentFlowPatient?.insuranceDetails?.guarantorPhone) && (
                      <div className="flex justify-start">
                        <button
                          type="button"
                          className="underline text-primary font-bold"
                          onClick={() => {
                            setFieldValue("responsibleParty", "");
                          }}>
                          Clear Selection
                        </button>
                      </div>
                    )}
                </div>

                {patientFlow === PatientFlowType.Dependant && (
                  <label htmlFor="">
                    <Field
                      name="responsibleParty"
                      type="radio"
                      checked={
                        values.responsibleParty === "PatientRelationship"
                      }
                      value="PatientRelationship"
                      className="mr-2"
                    />
                    Patient
                  </label>
                )}
                <div className="flex items-center justify-start">
                  <div className="flex flex-col gap-2">
                    <label htmlFor="">
                      <Field
                        name="responsibleParty"
                        type="radio"
                        checked={values.responsibleParty === "Yourself"}
                        value="Yourself"
                        className="mr-2"
                      />
                      Yourself
                      {patientFlow === PatientFlowType.Dependant &&
                      guardianDetails?.firstName &&
                      guardianDetails?.lastName
                        ? ` : ${guardianDetails?.firstName as string} ${
                            guardianDetails?.lastName as string
                          }`
                        : patientFlow === PatientFlowType.Yourself
                        ? ` : ${currentFlowPatient?.firstName as string} 
                      ${currentFlowPatient?.lastName as string}`
                        : ""}
                    </label>

                    {patientFlow === PatientFlowType.Dependant &&
                      values.responsibleParty === "Yourself" && (
                        <>
                          <FormikInput
                            name="dependantYourselfRelationship"
                            placeholder="Enter Relationship"
                            type="text"
                            label="Guarantor Relationship to Patient"
                            value={values.dependantYourselfRelationship}
                            component="select">
                            <option
                              className="text-gray-400 mt-4"
                              value=""
                              disabled>
                              Select Relationship
                            </option>

                            {relationships.map((relationship, index) => {
                              return (
                                <option key={index} value={relationship}>
                                  {relationship}
                                </option>
                              );
                            })}
                          </FormikInput>
                        </>
                      )}
                  </div>
                </div>
                <div className="flex items-center justify-start gap-2 mb-4">
                  <label htmlFor="Another-guarantor">
                    <Field
                      name="responsibleParty"
                      type="radio"
                      checked={values.responsibleParty === "Another guarantor"}
                      value="Another guarantor"
                      className="mr-2"
                    />
                    Another guarantor
                  </label>
                </div>

                {values.responsibleParty === "Another guarantor" && (
                  <>
                    <FormikInput
                      name="firstName"
                      placeholder="Enter First Name"
                      type="text"
                      label="Guarantor First Name"
                      value={values.firstName ?? ""}
                      component="input"></FormikInput>
                    <FormikInput
                      name="lastName"
                      placeholder="Enter Last Name"
                      type="text"
                      label="Guarantor Last Name"
                      value={values.lastName ?? ""}
                      component="input"></FormikInput>
                    <FormikInput
                      name="birthDate"
                      placeholder="MM/DD/YYYY"
                      type="date"
                      label="Guarantor Date of Birth"
                      component={DateSeparateInput}
                      value={values.birthDate}
                    />
                    <FormikInput
                      name="relationship"
                      placeholder="Enter Relationship"
                      type="text"
                      label="Guarantor Relationship to Patient"
                      value={values.relationship}
                      component="select">
                      <option className="text-gray-400" value="" disabled>
                        Select Relationship
                      </option>

                      {relationships.map((relationship, index) => {
                        return (
                          <option key={index} value={relationship}>
                            {relationship}
                          </option>
                        );
                      })}
                    </FormikInput>
                    <FormikInput
                      name="mobilePhoneNumber"
                      placeholder=" 000 000 0000"
                      type="tel"
                      onInput={(e) =>
                        (e.target.value = e.target.value.replace(/[^\d+]/g, ""))
                      }
                      maxLength="10"
                      label="Guarantor Mobile Phone Number"
                      value={values.mobilePhoneNumber ?? ""}
                    />
                  </>
                )} */}
                <div
                  className="max-lg:fixed max-lg:bottom-0 max-lg:left-0
                max-lg:px-5 bg-white max-lg:w-full flex items-center gap-8 max-sm:gap-4 ">
                  <button
                    onClick={() => {
                      setStep((prevStep) => prevStep - 1);
                    }}
                    className="border font-bold border-gray-300 text-sm sm:text-base rounded-md px-6 py-4 max-lg:px-4"
                    type="button">
                    Back
                  </button>
                  {dirty ? (
                    <button
                      type="submit"
                      ref={NextBtn}
                      disabled={invalidFields?.length > 0}
                      className="w-full bg-primary px-12 py-4 my-4 text-white rounded-md font-bold disabled:bg-gray-400 disabled:cursor-not-allowed max-lg:px-16 max-sm:px-4">
                      {location.pathname === "/consent/patient-onboarding-3"
                        ? "Next Up: HIPLAC consent"
                        : patientFlow === PatientFlowType.Dependant
                        ? "Next Up: Guardian Details"
                        : "Next Up: Insurance Details"}
                    </button>
                  ) : (
                    <button
                      type="button"
                      ref={NextBtn}
                      disabled={invalidFields?.length > 0}
                      onClick={async () => {
                        const errors = await validateForm(values);
                        if (Object.keys(errors).length > 0) {
                          setTouched(
                            Object.keys(errors).reduce((acc, curr) => {
                              acc[curr] = true;
                              return acc;
                            }, {})
                          );
                          return;
                        }
                        setStep((prev) => prev + 1);
                      }}
                      className="w-full bg-primary px-12 py-4 my-4 text-white rounded-md font-bold disabled:bg-gray-400 disabled:cursor-not-allowed max-lg:px-16 max-sm:px-4">
                      {location.pathname === "/consent/patient-onboarding-3"
                        ? "Next Up: HIPLAC consent"
                        : patientFlow === PatientFlowType.Dependant
                        ? "Next Up: Guardian Details"
                        : "Next Up : Insurance Details"}
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </section>
      </div>
    </div>
  );
};

export default AdditionalFormFields;
