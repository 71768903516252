import { AxiosRequestConfig } from "axios";
import { urls } from "../../app/shared/config";
import { useConsumerStore } from "../../store";

const requestInterceptor = (config: AxiosRequestConfig) => {
  const authToken = useConsumerStore.getState().token;

  if (config.url === urls.googleMapsVerifyAddress) {
    return config;
  }

  if (authToken !== null) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${authToken}`,
      organization_code: process.env.REACT_APP_CARE2U_ORG_CODE,
    });
  }
  return config;
};

export default requestInterceptor;
