import { useLazyQuery, useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import {
  ADD_SERVICE_CONSUMER,
  CONSUMER_EPISODE_START,
} from "../../apollo/service-consumer/service-consumer.mutations";
import { GET_SERVICE_CONSUMER_BY_EMAIL_OR_MOBILE } from "../../apollo/service-consumer/service-consumer.queries";
import { IConsumerEpisode, IServiceConsumer } from "../../interfaces";
import websocketLoggerService from "../../services/websocket-logger";
import { useConsumerStore } from "../../store";
import { ScopeType } from "../shared/config";

const ConsumerLogin: React.FC = () => {
  const location = useLocation();

  const [token, setToken, setAuthenticationId] = useConsumerStore((state) => [
      state.token,
      state.setConsumerToken,
      state.setAuthenticationId,
    ]),
    { user, isLoading, getIdTokenClaims, loginWithRedirect } = useAuth0();

  const [addServiceConsumer] = useMutation<{
      addConsumer: IServiceConsumer;
    }>(ADD_SERVICE_CONSUMER),
    [addConsumerEpisode] = useMutation<{
      createConsumerEpisode: IConsumerEpisode;
    }>(CONSUMER_EPISODE_START),
    [getServiceConsumerByEmail] = useLazyQuery<{
      getConsumerByEmail: IServiceConsumer;
    }>(GET_SERVICE_CONSUMER_BY_EMAIL_OR_MOBILE, {
      context: {
        scope: ScopeType.Consumer,
      },
    });

  const setConsumerEpisode = useConsumerStore(
    (state) => state.setConsumerEpisode
  );

  React.useEffect(() => {
    if (isLoading) {
      return;
    }
    const pathname: string = location.state?.from.pathname;
    if (pathname) {
      sessionStorage.setItem("redirectUrl", pathname);
    }

    if (!user) {
      websocketLoggerService.sendMessage({
        eventType: `Auth0 Login`,
        eventSubType: `ser redirect to auth0 login`,
        eventData: `navigating to /login`,
      });
      const pathname: string = location.state?.from.pathname;
      if (pathname) {
        localStorage.setItem("redirectUrl", pathname);
      }
      loginWithRedirect({
        redirectUri: window.location.href,
        scope: "openid profile email phone",
      });
      return;
    }

    const getAccessToken = async () => {
      const token = await getIdTokenClaims();
      setToken(token.__raw, token.exp);
    };

    getAccessToken();
  }, [
    user,
    isLoading,
    setToken,
    getIdTokenClaims,
    loginWithRedirect,
    location.state?.from.pathname,
  ]);

  React.useEffect(() => {
    if (!token || !user) {
      return;
    }
    setAuthenticationId(user?.sub);
  }, [
    user,
    token,
    addServiceConsumer,
    getServiceConsumerByEmail,
    addConsumerEpisode,
    setConsumerEpisode,
    setAuthenticationId,
  ]);

  if (user && (user.email_verified || user.isGuestUser || !user.email)) {
    const redirectUrl = sessionStorage.getItem("redirectUrl");
    if (redirectUrl) {
      websocketLoggerService.sendMessage({
        eventType: `Auth0 Login`,
        eventSubType: `User signed in via auth0`,
        eventData: `navigating to ${redirectUrl}`,
      });
      return <Navigate to={redirectUrl} />;
    }
    websocketLoggerService.sendMessage({
      eventType: `Auth0 Login`,
      eventSubType: `User signed in via auth0`,
      eventData: `navigating to /consent/patient-onboarding-1`,
    });
    return <Navigate to="/consent/patient-onboarding-1" />;
  } else if (user && !user?.email_verified && !user?.isGuestUser) {
    websocketLoggerService.sendMessage({
      eventType: `Auth0 Login`,
      eventSubType: `User signed in via auth0`,
      eventData: `navigating to /verifyemail`,
    });
    return <Navigate to="/verifyemail" />;
  }

  return null;
};

export default ConsumerLogin;
