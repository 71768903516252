import { useMutation } from "@apollo/client";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import { UPDATE_CONSUMER_GUARDIAN } from "../../apollo";
import { IServiceConsumer } from "../../interfaces";
import { loggerService } from "../../services";
import websocketLoggerService from "../../services/websocket-logger";
import { useConsumerStore } from "../../store";
import useFormStore from "../../store/form/form.store";
import DateSeparateInput from "../shared/components/date-separate-input.component";
import FieldError from "../shared/components/field-error.component";
import FormikInput from "../shared/components/formik-input.component";
import GuideBanner from "../shared/components/guide-banner.component";
import UploadFile from "../shared/components/upload-file.component";
import { ScopeType, regexConstants } from "../shared/config";
import { useServiceConsumer } from "../shared/hooks";
import { uploadTos3 } from "../shared/utils/file-upload";
import {
  isPatientCRUDFlow,
  parseDOB,
  parseISODateStr,
} from "../shared/utils/helper.util";

const phoneRegExp = regexConstants.phoneRegExp;
const formSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First Name is required")
    .matches(regexConstants.firstNameRegExp, "Please enter a valid name."),
  lastName: Yup.string()
    .required("Last Name is required")
    .matches(regexConstants.lastNameRegExp, "Please enter a valid name."),
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Please enter a valid email address"),
  birthDate: Yup.date().required("Healthcare Proxy date of birth is required."),
  gender: Yup.string().required(
    "Healthcare Proxy Sex assigned at birth is required."
  ),
  mobileNumber: Yup.string()
    .required("Healthcare Proxy phone number is required.")
    .matches(phoneRegExp, "Please enter a valid phone number"),
  patientFrontLicense: Yup.string().required(
    "Healthcare Proxy front License Required"
  ),
});

interface GuardianInfoFormProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  NextBtn: React.MutableRefObject<HTMLButtonElement>;
}

const GuardianInfoForm: React.FC<GuardianInfoFormProps> = ({
  setStep,
  NextBtn,
}) => {
  const [isValidDate] = useFormStore((state) => [state.isValidDate]);
  const serviceConsumer: any = useServiceConsumer(),
    updateConsumer = useConsumerStore((store) => store.setConsumer),
    [updateConsumerGuardian] = useMutation<{
      updateConsumer: IServiceConsumer;
    }>(UPDATE_CONSUMER_GUARDIAN);

  const buttonText = isPatientCRUDFlow()
    ? "Next up: Consent Sign"
    : "Next up: Insurance Details";

  // const { data } = useQuery<{ consumerById: any }>(GET_CONSUMER_GUARDIAN, {
  //     variables: {
  //       consumer: {
  //         consumerId: serviceConsumer.id,
  //         organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
  //       },
  //     },
  //     context: {
  //       scope: ScopeType.Consumer,
  //     },
  //   }),
  //   { consumerById } = data || {};
  // const serviceConsumer = consumerById;

  websocketLoggerService.sendMessage({
    eventType: "GuardianInfoForm",
    eventSubType: "API GET_CONSUMER_GUARDIAN success",
    eventData: `cosumerId: ${serviceConsumer.id as string}`,
  });

  const handleSubmit = async (values, setFieldError, setSubmitting) => {
    try {
      const {
        firstName,
        lastName,
        email,
        birthDate,
        gender,
        mobileNumber,
        patientFrontLicense,
      }: {
        firstName: string;
        lastName: string;
        email: string;
        birthDate: string;
        gender: string;
        mobileNumber: string;
        patientFrontLicense: string;
      } = values;

      // check if patientFrontLicense is base64 or not
      let uploadedImg = null;
      if (patientFrontLicense.startsWith("data:")) {
        uploadedImg = await uploadTos3(
          patientFrontLicense,
          `patientFrontLicense${uuid()}`
        );
      }

      const payload = {
        id: serviceConsumer?.id,
        firstName,
        lastName,
        gender,
        mobileNumber,
        email,
        birthDate: parseISODateStr(birthDate),
        timeZone: serviceConsumer.timeZone,
        organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
        idProof: {
          ...serviceConsumer?.idProof,
          drivingLicenseFront: {
            name:
              uploadedImg?.fileName ||
              serviceConsumer?.idProof?.drivingLicenseFront?.name,
            cloudId:
              uploadedImg?.cloudId ||
              serviceConsumer?.idProof?.drivingLicenseFront?.cloudId,
            mimeType:
              uploadedImg?.mimeType ||
              serviceConsumer?.idProof?.drivingLicenseFront?.mimeType,
          },
        },
        insuranceDetails: {},
        dependants: [],
      };

      if (payload.mobileNumber) {
        payload.mobileNumber = `+1${payload.mobileNumber}`;
      }

      const { data } = await updateConsumerGuardian({
        variables: {
          consumer: payload,
        },
        context: {
          scope: ScopeType.Consumer,
        },
      });

      websocketLoggerService.sendMessage({
        eventType: "GuardianInfoForm",
        eventSubType: "API UPDATE_CONSUMER_GUARDIAN success",
        eventData: `cosumerId: ${serviceConsumer.id as string}`,
      });

      updateConsumer(data.updateConsumer);

      setStep((prevStep) => prevStep + 1);
    } catch (err) {
      websocketLoggerService.sendMessage({
        eventType: "GuardianInfoForm",
        eventSubType: "ERROR API UPDATE_CONSUMER_GUARDIAN failed",
        eventData: `Error ${JSON.stringify(err)}`,
      });
      toast.error("Something went wrong. Please try again.");
      loggerService.error("PEDGuardian", err);
      setSubmitting(false);
    }
  };

  if (!serviceConsumer) return null;

  return (
    <div className="w-3/4 max-lg:w-full">
      <div className="px-4">
        <h1 className="font-bold text-3xl">Healthcare Proxy Details</h1>

        <GuideBanner body="Please add Healthcare Proxy Information" />

        <main className="mt-6">
          <div className="flex items-center my-6 gap-20">
            <p className="text-primary  uppercase text-sm ">
              Healthcare Proxy Details
            </p>
            <div className="w-full h-[2px] bg-primary/60 flex-1"></div>
          </div>
          <Formik
            initialValues={{
              id: serviceConsumer?.id,
              firstName: serviceConsumer?.firstName || "",
              lastName: serviceConsumer?.lastName || "",
              email:
                (serviceConsumer?.email !== "none" && serviceConsumer?.email) ||
                "",
              birthDate:
                (serviceConsumer?.birthDate &&
                  !serviceConsumer?.isGuestUser &&
                  parseDOB(serviceConsumer?.birthDate)) ||
                null,
              gender:
                (!serviceConsumer?.isGuestUser &&
                  serviceConsumer?.gender !== "UNKNOWN" &&
                  serviceConsumer?.gender) ||
                "",
              mobileNumber:
                (!serviceConsumer?.isGuestUser &&
                  serviceConsumer?.mobileNumber?.slice(2)) ||
                "",
              patientFrontLicense:
                serviceConsumer?.idProof?.drivingLicenseFront?.cloudUrl || "",
              patientFrontLicenseMimeType:
                serviceConsumer?.idProof?.drivingLicenseFront?.mimeType || "",
            }}
            enableReinitialize
            validationSchema={formSchema}
            onSubmit={(values, { setSubmitting, setFieldError }) => {
              handleSubmit(values, setFieldError, setSubmitting);
            }}>
            {({
              values,
              initialValues,
              isSubmitting,
              setFieldValue,
              dirty,
              validateForm,
              setTouched,
            }) => (
              <Form className="flex flex-col gap-4">
                <section className={`flex flex-col gap-4`}>
                  <div className="flex gap-6 items-start max-sm:flex-col max-sm:w-full max-sm:gap-3">
                    <FormikInput
                      name="firstName"
                      placeholder="Enter First Name"
                      type="text"
                      label="Healthcare Proxy First Name"
                      value={values.firstName}
                      onChange={(e) => {
                        setFieldValue("firstName", e.target.value.trim());
                      }}
                    />
                    <FormikInput
                      name="lastName"
                      placeholder="Enter Last Name"
                      type="text"
                      label="Healthcare Proxy Last Name"
                      value={values.lastName}
                      onChange={(e) => {
                        setFieldValue("lastName", e.target.value.trim());
                      }}
                    />
                  </div>

                  <FormikInput
                    name="email"
                    placeholder="Enter Email"
                    type="email"
                    disabled={
                      initialValues.email !== "" &&
                      initialValues.email !== "none" &&
                      !serviceConsumer?.isGuestUser
                    }
                    label="Healthcare Proxy Email"
                    value={values.email}
                  />

                  <div className="flex gap-6 items-start max-sm:flex-col max-sm:w-full max-sm:gap-3">
                    <FormikInput
                      name="birthDate"
                      placeholder="MM/DD/YYYY"
                      type="date"
                      label="Healthcare Proxy Date of Birth"
                      component={DateSeparateInput}
                      value={values.birthDate}
                    />

                    <FormikInput
                      name="gender"
                      placeholder="Select"
                      type="text"
                      label="Healthcare Proxy Sex Assigned at Birth"
                      value={values.gender}
                      component="select">
                      <option className="text-gray-400" value="" disabled>
                        Select
                      </option>
                      <option value="MALE">Male</option>
                      <option value="FEMALE">Female</option>
                    </FormikInput>
                  </div>

                  <FormikInput
                    name="mobileNumber"
                    placeholder="000 000 0000"
                    type="tel"
                    maxLength="10"
                    disabled={
                      !serviceConsumer?.isGuestUser &&
                      initialValues.mobileNumber !== ""
                    }
                    label="Healthcare Proxy Phone Number"
                    value={values.mobileNumber}
                  />

                  <div className="flex items-center mt-4 mb-2 gap-20">
                    <p className="text-primary  uppercase text-sm ">
                      Healthcare Proxy's proof of Identity
                    </p>
                    <div className="w-full h-[2px] bg-primary/60 flex-1"></div>
                  </div>

                  <div>
                    <label className="mb-2 text-sm font-semibold ">
                      Front of Healthcare Proxy's Driver's License
                      <span className="text-red-500 text-base">*</span>
                    </label>
                    <Field
                      type="file"
                      data-clarity-mask="True"
                      id="patientFrontLicense"
                      name="patientFrontLicense"
                      placeholder="Front of Patient's Driver's License"
                      component={UploadFile}
                      mimeType={values.patientFrontLicenseMimeType}
                    />
                    <ErrorMessage name="patientFrontLicense">
                      {(msg) => <FieldError message={msg} />}
                    </ErrorMessage>
                  </div>
                </section>

                <div className="sticky bottom-0 sm:static  flex items-center gap-8 bg-white">
                  <button
                    onClick={() => {
                      setStep((prevStep) => prevStep - 1);
                    }}
                    className="border font-bold border-gray-300 text-sm sm:text-base rounded-md px-6 py-4 max-lg:px-4"
                    type="button">
                    Back
                  </button>

                  {dirty ? (
                    <button
                      type="submit"
                      ref={NextBtn}
                      disabled={!isValidDate}
                      className="w-full bg-primary px-8 sm:px-10 py-4 my-4 text-sm sm:text-base text-white rounded-md font-bold">
                      {isSubmitting ? (
                        <div className="flex gap-2 items-center">
                          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                          <div>Submitting...</div>
                        </div>
                      ) : (
                        buttonText
                      )}
                    </button>
                  ) : (
                    <button
                      type="button"
                      ref={NextBtn}
                      disabled={!isValidDate}
                      onClick={async () => {
                        const errors = await validateForm(values);
                        if (Object.keys(errors).length > 0) {
                          setTouched(
                            Object.keys(errors).reduce((acc, curr) => {
                              acc[curr] = true;
                              return acc;
                            }, {})
                          );
                          return;
                        }
                        setStep((prev) => prev + 1);
                      }}
                      className="w-full bg-primary px-8 sm:px-10 py-4 my-4 text-sm sm:text-base text-white rounded-md font-bold">
                      {isSubmitting ? (
                        <div className="flex gap-2 items-center">
                          <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                          <div>Submitting...</div>
                        </div>
                      ) : (
                        buttonText
                      )}
                    </button>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </main>
      </div>
    </div>
  );
};
export default GuardianInfoForm;
