import { gql } from "@apollo/client";

export const ADD_SERVICE_CONSUMER = gql`
  mutation Mutation($consumer: ConsumerInput!) {
    addConsumer(consumer: $consumer) {
      id
      firstName
      lastName
      email
      gender
      birthDate
      mobileNumber
      homePhoneNumber
      timeZone
      organizationId
      isPatient
      idProof {
        idNumber
        state
        drivingLicenseFront {
          name
          cloudId
          cloudUrl
          mimeType
        }
        drivingLicenseBack {
          name
          cloudId
          cloudUrl
          mimeType
        }
      }
      dependants {
        id
        firstName
        lastName
        email
        gender
        birthDate
        mobileNumber
        homePhoneNumber
        timeZone
        organizationId
        isPatient
      }
    }
  }
`;

export const LINK_CONSUMER = gql`
  mutation Mutation($linkGuardianToConsumer: LinkGuardianToConsumerInput!) {
    linkConsumers(linkGuardianToConsumer: $linkGuardianToConsumer) {
      id
      firstName
      lastName
      email
      mobileNumber
      homePhoneNumber
      timeZone
      gender
      organizationId
      isPatient
      addresses {
        id
        line1
        line2
        zipCode
        state
        district
        country
        city
      }
      idProof {
        idNumber
        state
        drivingLicenseFront {
          name
          cloudId
          cloudUrl
          mimeType
        }
        drivingLicenseBack {
          name
          cloudId
          cloudUrl
          mimeType
        }
      }
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        backSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        secondaryFrontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        secondaryBackSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        tertiaryFrontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        tertiaryBackSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorRelation
      }
      dependants {
        id
        firstName
        lastName
        email
        gender
        birthDate
        mobileNumber
        homePhoneNumber
        timeZone
        organizationId
        isPatient
      }
    }
  }
`;

export const UPDATE_CONSUMER_GUARDIAN = gql`
  mutation Mutation($consumer: ConsumerInput!) {
    updateConsumer(consumer: $consumer) {
      id
      firstName
      lastName
      email
      gender
      birthDate
      mobileNumber
      homePhoneNumber
      timeZone
      organizationId
      isPatient
      addresses {
        id
        line1
        line2
        zipCode
        state
        district
        country
        city
      }
      idProof {
        idNumber
        state
        drivingLicenseFront {
          name
          cloudId
          cloudUrl
          mimeType
        }
        drivingLicenseBack {
          name
          cloudId
          cloudUrl
          mimeType
        }
      }
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        backSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        secondaryFrontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        secondaryBackSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        tertiaryFrontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        tertiaryBackSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorRelation
      }
      dependants {
        id
        firstName
        lastName
        email
        gender
        birthDate
        mobileNumber
        homePhoneNumber
        timeZone
        organizationId
        isPatient
        addresses {
          id
          line1
          line2
          zipCode
          state
          district
          country
          city
        }
      }
    }
  }
`;

export const GET_STRIPE_INTENT_SECRET = gql`
  mutation Mutation {
    addPaymentIntent {
      clientSecret
    }
  }
`;

export const CONSUMER_EPISODE_START = gql`
  mutation createConsumerEpisode($consumerEpisodeInput: ConsumerEpisodeInput!) {
    createConsumerEpisode(consumerEpisodeInput: $consumerEpisodeInput) {
      id
      isComplete
    }
  }
`;

export const CONSUMER_EPISODE_UPDATE = gql`
  mutation updateConsumerEpisode($consumerEpisodeInput: ConsumerEpisodeInput!) {
    updateConsumerEpisode(consumerEpisodeInput: $consumerEpisodeInput) {
      id
      isComplete
    }
  }
`;

export const BOOK_BLOCKED_APPOINTMENT = gql`
  mutation BookBlockedAppointmentMutation(
    $appointment: BlockedSlotAppointmentInput!
  ) {
    bookBlockedAppointment(appointment: $appointment) {
      token
    }
  }
`;

export const CONSUMER_TERMS_CONFIRM = gql`
  mutation createAgreementMutation($agreement: AgreementInput!) {
    createAgreement(agreement: $agreement) {
      id
      agreementTemplateId
      personId
      fileId
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_DRIVING_LICENSE = gql`
  mutation Mutation($consumer: ConsumerInput!) {
    updateConsumer(consumer: $consumer) {
      id
      firstName
      lastName
      email
      mobileNumber
      homePhoneNumber
      organizationId
      isPatient
      addresses {
        id
        line1
        line2
        zipCode
        state
        district
        country
        city
      }
      idProof {
        idNumber
        state
        drivingLicenseFront {
          name
          cloudId
          cloudUrl
          mimeType
        }
        drivingLicenseBack {
          name
          cloudId
          cloudUrl
          mimeType
        }
      }
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        backSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        secondaryFrontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        secondaryBackSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        tertiaryFrontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        tertiaryBackSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorRelation
        guarantorBirthDate
      }
    }
  }
`;

export const UPDATE_CONSUMER_IDENTITY_PROOF = gql`
  mutation Mutation($consumer: ConsumerInput!) {
    updateConsumer(consumer: $consumer) {
      id
      firstName
      lastName
      email
      mobileNumber
      homePhoneNumber
      organizationId
      isPatient
      addresses {
        id
        line1
        line2
        zipCode
        state
        district
        country
        city
      }
      idProof {
        idNumber
        state
        drivingLicenseFront {
          name
          cloudId
          cloudUrl
          mimeType
        }
        drivingLicenseBack {
          name
          cloudId
          cloudUrl
          mimeType
        }
        healthCareProxyDocument {
          name
          cloudId
          cloudUrl
          mimeType
        }
      }
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        backSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        secondaryFrontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        secondaryBackSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        tertiaryFrontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        tertiaryBackSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorRelation
        guarantorBirthDate
      }
    }
  }
`;

export const UPDATE_INSURANCE_DETAILS = gql`
  mutation Mutation($consumer: ConsumerInput!) {
    updateConsumer(consumer: $consumer) {
      id
      firstName
      lastName
      email
      mobileNumber
      homePhoneNumber
      organizationId
      isPatient
      addresses {
        id
        line1
        line2
        zipCode
        state
        district
        country
        city
      }
      idProof {
        idNumber
        state
        drivingLicenseFront {
          name
          cloudId
          cloudUrl
          mimeType
        }
        drivingLicenseBack {
          name
          cloudId
          cloudUrl
          mimeType
        }
      }
      insuranceDetails {
        idNumber
        groupNumber
        provider
        frontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        backSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        secondaryFrontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        secondaryBackSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        tertiaryFrontSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        tertiaryBackSide {
          name
          cloudId
          cloudUrl
          mimeType
        }
        guarantorFirstName
        guarantorLastName
        guarantorPhone
        guarantorRelation
        guarantorBirthDate
      }
    }
  }
`;

export const CONSUMER_GUEST_TOKEN = gql`
  mutation Mutation($guest: GuestInput!) {
    guestUserToken(guest: $guest) {
      token
    }
  }
`;

export const CREATE_PRESIGNED_URL = gql`
  mutation createPresignedUrl($cloudStorageInput: CloudStorageInput!) {
    createPresignedUrl(cloudStorageInput: $cloudStorageInput) {
      signedUrl
      cloudId
    }
  }
`;
