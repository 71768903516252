import axios, { AxiosRequestConfig } from "axios";
import { toast } from "react-toastify";
import { CREATE_PRESIGNED_URL } from "../../../apollo";
import { clientWithOutLoader } from "../../../apollo/client";
import { setupProgressBar } from "../../../services/interceptors";
export async function uploadTos3(
  base64Resource: string,
  fileName: string,
  loader: boolean = true
): Promise<{ cloudId: string; fileName: string; mimeType: string } | null> {
  if (!base64Resource) return null;

  try {
    const splitData = base64Resource.split(",");
    const mimeType = splitData[0].split(":")[1].split(";")[0];
    const Base64data = atob(splitData[1]);

    // if mimeType is image or pdf
    if (mimeType.startsWith("image")) {
      // check if mimeType extension and append to fileName
      const extension = mimeType.split("/")[1];
      if (fileName.endsWith(extension)) {
        fileName = `${fileName}`;
      } else {
        fileName = `${fileName}.${extension}`;
      }
    } else {
      fileName = `${fileName}.pdf`;
    }

    // Convert the image data to an array buffer
    const arrayBuffer = new ArrayBuffer(Base64data.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < Base64data.length; i++) {
      uint8Array[i] = Base64data.charCodeAt(i);
    }
    // Create a Blob from the array buffer
    const blob = new Blob([uint8Array], { type: mimeType });

    // Create a File object from the Blob (you can specify a filename)
    const file = new File([blob], fileName, { type: mimeType });

    // get presigned url
    const { data } = await clientWithOutLoader.mutate({
      mutation: CREATE_PRESIGNED_URL,
      variables: {
        cloudStorageInput: {
          organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
          fileName,
        },
      },
    });
    const {
      createPresignedUrl: { cloudId, signedUrl },
    } = data;

    // upload to s3
    const config: AxiosRequestConfig = {
      method: "put",
      maxBodyLength: Infinity,
      maxContentLength: Infinity,
      url: signedUrl,
      headers: {
        "Content-Type": mimeType,
      },
      data: file,
    };

    const axiosInstance = axios.create();
    if (loader) {
      setupProgressBar(axiosInstance);
    }

    return await new Promise<{
      cloudId: string;
      fileName: string;
      mimeType: string;
    }>((resolve, reject) => {
      axiosInstance
        .request(config)
        .then((response) => {
          if (response.status === 200) {
            resolve({ cloudId, fileName, mimeType });
          } else {
            const error = new Error("Upload to S3 failed");
            toast.error("Upload to S3 failed");
            reject(error);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });

    // if uploadImgRes with status 200, return cloudId and fileName
  } catch (error) {
    console.log("error on upload to s3", error);
  }
}
