import React from "react";
import guideImg from "../../../assets/images/guide.png";

interface GuideBannerProps {
  body: string;
}

const GuideBanner: React.FC<GuideBannerProps> = ({ body }) => {
  return (
    <div
      className={`flex items-start justify-between w-full bg-[#1E7FC21A] p-4 mt-5 rounded-md gap-3`}>
      <div className="flex items-center justify-start gap-5">
        <img src={guideImg} alt="Guide" />
        <div>
          <p className="max-lg:text-sm">{body}</p>
        </div>
      </div>
      {/* <button onClick={() => setShow(false)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button> */}
    </div>
  );
};

export default GuideBanner;
