import React from "react";
import { loggerService } from "../../../services";

interface IErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<
  any,
  IErrorBoundaryState
> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: unknown, info: unknown) {
    // Display fallback UI
    // this.setState({ hasError: true });
    loggerService.error("ErrorBoundary", error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return <>{this.props.children}</>;
  }
}

// ErrorBoundary.propTypes = {
//     children: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]).isRequired,
//   };
