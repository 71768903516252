import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { CONSUMER_GUEST_TOKEN, GET_GUEST_CONSUMER } from "../../apollo";
import { IServiceConsumer } from "../../interfaces";
import { loggerService } from "../../services";
import dateService from "../../services/date.service";
import { useConsumerStore } from "../../store";
import { ScopeType } from "../shared/config";
import { useServiceConsumer } from "../shared/hooks";

const GuestLogin = () => {
  const [getGuestUserToken] = useMutation<{
      guestUserToken: { token: string };
    }>(CONSUMER_GUEST_TOKEN),
    [getGuestUserDetails] = useLazyQuery<{ guestUser: IServiceConsumer }>(
      GET_GUEST_CONSUMER,
      {
        variables: {
          organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
        },
        context: {
          scope: ScopeType.Consumer,
        },
      }
    );

  const serviceConsumer = useServiceConsumer(),
    setServiceConsumer = useConsumerStore((state) => state.setConsumer),
    [token, setToken] = useConsumerStore((state) => [
      state.token,
      state.setConsumerToken,
    ]);

  useEffect(() => {
    const getAuthToken = async () => {
      try {
        const { data } = await getGuestUserToken({
          variables: {
            guest: {
              timeZone: dateService.getUserTimezone(),
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            },
          },
          context: {
            scope: ScopeType.Consumer,
          },
        });
        setToken(data.guestUserToken.token);
      } catch (err) {
        loggerService.error(
          "Error while generating the consumer guest token",
          err
        );
      }
    };

    getAuthToken();
  }, [getGuestUserToken, setToken]);

  useEffect(() => {
    if (!token) return;
    const getGuestUser = async () => {
      try {
        const { data } = await getGuestUserDetails();
        const serviceConsumer = { ...data?.guestUser };
        serviceConsumer.isGuestUser = true;

        if (!serviceConsumer.timeZone) {
          serviceConsumer.timeZone = dateService.getUserTimezone();
        }

        setServiceConsumer(serviceConsumer);
      } catch (err) {
        loggerService.error(
          "Error while getting the guest consumer details",
          err
        );
      }
    };

    getGuestUser();
  }, [token, getGuestUserDetails, setServiceConsumer]);

  if (serviceConsumer?.id) {
    const redirectUrl = sessionStorage.getItem("redirectUrl");
    if (redirectUrl) {
      return <Navigate to={redirectUrl} />;
    }
    return <Navigate to="/consent/patient-onboarding-1" />;
  }

  return null;
};

export default GuestLogin;
