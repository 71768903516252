// auth0Service.ts
import auth0, { Auth0DecodedHash, Auth0Error } from "auth0-js";

class Auth0Service {
  private readonly auth0 = new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: `${window.location.origin}`,
    responseType: "token id_token",
    scope: "openid profile email",
  });

  public async renewToken(): Promise<Auth0DecodedHash> {
    return await new Promise((resolve, reject) => {
      this.auth0.checkSession(
        {},
        (err: Auth0Error | null, authResult?: Auth0DecodedHash) => {
          if (err) {
            reject(err);
          } else if (authResult?.accessToken && authResult.idToken) {
            resolve(authResult);
          }
        }
      );
    });
  }
}

const auth0Service = new Auth0Service();

export default auth0Service;
