import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./app.component";
import ConsumerLogin from "./auth/auth0.component";
import GuestLogin from "./auth/guest-login.component";
import Logout from "./auth/logout.component";

import { NotFound, PrivateRoute, Unauthorized } from "./shared/components";
import Verifyemail from "./shared/components/verify-email.component";
import StepFlow from "./step-flow/step-flow";
import StepSuccess from "./step-flow/step-success";

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path="login" element={<ConsumerLogin />} />
          <Route path="guest" element={<GuestLogin />} />
          <Route path="logout" element={<Logout />} />
          <Route element={<PrivateRoute />}>
            <Route path="consent/patient-onboarding-1" element={<StepFlow />} />
            <Route path="consent/patient-onboarding-3" element={<StepFlow />} />
            <Route path="consent/patient-onboarding-4" element={<StepFlow />} />
            <Route path="consent/patient-onboarding-5" element={<StepFlow />} />
            <Route path="consent/patient-onboarding-6" element={<StepFlow />} />
            <Route path="consent/patient-onboarding-7" element={<StepFlow />} />
            <Route path="consent/patient-onboarding-8" element={<StepFlow />} />
            <Route path="consent/patient-onboarding-9" element={<StepFlow />} />
            <Route
              path="consent/patient-onboarding-10"
              element={<StepFlow />}
            />

            <Route path="success" element={<StepSuccess />} />
          </Route>

          <Route path="verifyemail" element={<Verifyemail />} />
        </Route>
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
