/* eslint-disable no-unused-vars */

export enum OnboardingType {
  Onboarding1 = "patient-onboarding-1",
  Onboarding3 = "patient-onboarding-3",
  Onboarding4 = "patient-onboarding-4",
  Onboarding5 = "patient-onboarding-5",
  Onboarding6 = "patient-onboarding-6",
  Onboarding7 = "patient-onboarding-7",
  Onboarding8 = "patient-onboarding-8",
  Onboarding9 = "patient-onboarding-9",
  Onboarding10 = "patient-onboarding-10",
}

export enum OnboardingStepType {
  Patient = "patient",
  Guardian = "guardian",
  Insurance = "insurance",
  Payment = "payment",
  Consent = "consent",
  AdditionalFormFields = "additional-details",
}

export enum OnboardPatientType {
  Self = "Yourself",
  Other = "Dependant",
}
