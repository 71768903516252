import moment from "moment";

export function doesExist(value: any) {
  return typeof value !== "undefined" && value !== null;
}

export function parseDOB(value: string, format: string = "MM/DD/YYYY") {
  if (!value) return null;
  // const date = value.slice(0, 10);
  const date = value;
  return moment(date, "YYYY-MM-DD").format(format);
}

export function debounce(func: Function, wait: number, immediate: boolean) {
  let timeout: any;
  return function (this: any, ...args: any[]) {
    const context = this;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function getPropertyValue<T>(
  object: { [key: string]: any },
  propertyPath: string,
  defaultValue: any = null
): T {
  return doesObjectContainProperty(object, propertyPath)
    ? propertyPath.split(".").reduce((previous, current) => {
        return previous[current];
      }, object)
    : defaultValue;
}

export function doesObjectContainProperty(
  object: { [key: string]: any },
  propertyPath: string
): boolean {
  // If there's nothing to check
  if (typeof object !== "object" || !object || !Object.keys(object).length) {
    return false;
  }

  // If there's nothing to check
  if (!propertyPath?.length) {
    return false;
  }

  try {
    // Iterate through propertyPath to dig into the object
    const finalValue = propertyPath.split(".").reduce((previous, current) => {
      // No hasOwnProperty check
      return typeof previous !== "undefined" && previous !== null
        ? previous[current]
        : undefined;
    }, object);
    // We specifically want to check for undefined & null to check if value exist here
    return typeof finalValue !== "undefined" && finalValue !== null;
  } catch (error) {
    // If the path has a wrong turn, the reduce function will throw an error
    return false;
  }
}

export function getSubDomain() {
  const url = window.location.hostname;
  const subDomain = url.split(".")[0];

  if (subDomain === "www") {
    return "";
  } else if (subDomain === "localhost") {
    return "";
  } else if (subDomain === "azo") {
    return "";
  }

  return subDomain;
}

export function parseISODateStr(value: string) {
  if (!value) return "";

  const inputDate = value;
  const parts = inputDate.split("/");
  const dateObject = `${parts[2]}-${parts[0]}-${parts[1]}`;

  return dateObject;
}

export function isPatientCRUDFlow() {
  return (
    window.location.pathname === "/consent/patient-onboarding-3" ||
    window.location.pathname === "/consent/patient-onboarding-9" ||
    window.location.pathname === "/consent/patient-onboarding-10"
  );
}
