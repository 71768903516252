export enum patientDetailsGuideBanner {
  onboarding3 = "High Intensity Physician Led Advanced Care (HIPLAC), also know as an alternative to Hospital Level care provided in the Home.",
  onboarding9 = "Select the patient who will be using Care2U services.",
  onboarding10 = "With this consent you confirm that the patient does not have any health insurance coverage and understand the cost of the visit based upon the service selected.",
  rest = "Add the patient's information who will be using Care2U services. Healthcare proxy can add their details after patient's information.",
}

export enum patientDetailsQuestion {
  onboarding3 = "Please select the name of the patient that is providing consent to enrollment for our hospital-level care at home?",
  onboarding9 = "Who are you signing the consent for today?",
  onboarding10 = "Who will be providing Attestation for no insurance",
  rest = "Who are you registering for today?",
}

export enum DuplicatePatientCheckMessages {
  singlePaitentWithoutMobileNumber = "It looks like the patient already exists and has no mobile phone added, please reach out to Care2U team on below phone number to get existing patient 6 digit code or new patient 6 digit code",
  multiplePatients = "It looks like the patient already exists and has multiple matching records, please reach out to Care2U team on below phone number to get new patient 6 digit code.",
}
