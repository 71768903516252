import React from "react";
import { useNavigate } from "react-router-dom";
import care2u from "../../../assets/images/care2u.png";
import websocketLoggerService from "../../../services/websocket-logger";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  return (
    <div className="bg-white shadow h-16 flex items-center justify-between px-32 max-lg:px-5">
      <img className="h-10" src={care2u} alt="Care2U Logo" />
      <button
        className="underline uppercase tracking-wider"
        onClick={() => {
          websocketLoggerService.sendMessage({
            eventType: "Logout button clicked",
            eventSubType: "Logout button clicked",
            eventData: `Location: ${location}`,
          });

          navigate("/logout");
        }}>
        Save & Exit
      </button>
    </div>
  );
};

export default Header;
