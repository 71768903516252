import React, { useEffect } from "react";
import dateService from "../../../services/date.service";
import useFormStore from "../../../store/form/form.store";
import FieldError from "./field-error.component";

const DateSeparateInput = ({
  field,
  form: { setFieldValue, setFieldError },
  ...props
}) => {
  // constants
  const Day = "Day";
  const Month = "Month";
  const Year = "Year";

  const [value, setValue] = React.useState<{
    Day: string;
    Month: string;
    Year: string;
  }>({
    Day: null,
    Month: null,
    Year: null,
  });

  const [error, setError] = React.useState<string | null>(null);
  const [setIsValidDate] = useFormStore((state) => [state.setIsValidDate]);
  const dayRef = React.useRef<HTMLInputElement>(null);
  const monthRef = React.useRef<HTMLInputElement>(null);
  const yearRef = React.useRef<HTMLInputElement>(null);

  const handleOnChange = (newValue: string, type) => {
    const newDate = { ...value, [type]: newValue };
    setValue(newDate);

    if (type === Day || type === Month) {
      if (newValue.length === 1 && parseInt(newValue) !== 0) {
        newValue = `0${newValue}`;
      }
    }
    const date = `${newDate?.Month || ""}/${newDate?.Day || ""}/${
      newDate?.Year || ""
    }`;

    const valid = dateService.isValidDate(date);
    try {
      if (valid) {
        setFieldValue(field.name, date);
        setFieldError(field.name, null);
        setError(null);
        setIsValidDate(true);
      } else {
        setFieldValue(field.name, date);
        if (newDate?.Month && newDate?.Day && newDate?.Year) {
          setIsValidDate(false);
        } else {
          setIsValidDate(false);
          setFieldError("birthDate", null);
          setError(null);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnBlur = (e) => {
    if (value?.Month?.length === 1 && parseInt(value?.Month) !== 0) {
      setValue({ ...value, Month: `0${value.Month}` });
      value.Month = `0${value.Month}`;
    }
    if (value?.Day?.length === 1 && parseInt(value?.Day) !== 0) {
      setValue({ ...value, Day: `0${value.Day}` });
      value.Day = `0${value.Day}`;
    }

    const date = `${value?.Month || ""}/${value?.Day || ""}/${
      value?.Year || ""
    }`;

    if (
      e.relatedTarget?.id === "day" ||
      e.relatedTarget?.id === "month" ||
      e.relatedTarget?.id === "year"
    )
      return;

    const valid = dateService.isValidDate(date);
    try {
      if (valid) {
        setFieldError(field.name, null);
        setError(null);
        setIsValidDate(true);
      } else {
        setFieldError("birthDate", "Invalid date");
        setError("Invalid date");
        setIsValidDate(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const date = `${value?.Month || ""}/${value?.Day || ""}/${
      value?.Year || ""
    }`;
    if (dateService.isValidDate(date)) {
      setIsValidDate(true);
    }
  }, [setIsValidDate, value]);

  useEffect(() => {
    setValue({
      Day: field.value
        ? field.value.split("/")?.map(String)?.[1]?.toString() || null
        : null,
      Month: field.value
        ? field.value.split("/")?.map(String)?.[0]?.toString() || null
        : null,
      Year: field.value
        ? field.value.split("/")?.map(String)?.[2]?.toString() || null
        : null,
    });
  }, [field.value]);

  return (
    <div {...field} {...props} className="border-none p-0 mt-1 w-full">
      <div
        className="flex gap-0 items-center border-none w-full"
        onBlur={handleOnBlur}>
        <input
          type="text"
          id="month"
          ref={monthRef}
          inputMode="numeric"
          pattern="[0-9]*"
          placeholder="MM"
          value={value.Month || ""}
          className="w-1/3 border border-gray-300 rounded-tl-md rounded-bl-md  py-3 px-2 "
          onKeyDown={(e) => {
            if (
              e.keyCode !== 8 &&
              e.keyCode !== 13 &&
              e.keyCode !== 37 &&
              e.keyCode !== 39 &&
              e.keyCode !== 9 &&
              (e.keyCode < 48 || e.keyCode > 57)
            ) {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            if (parseInt(e.target.value) > 12) {
              e.target.value = "12";
            }
            handleOnChange(e.target.value, Month);
            if (e.target.value.length === 2) {
              setTimeout(() => {
                dayRef.current.focus();
              }, 0);
            }
          }}
          maxLength={2}
        />
        <input
          type="text"
          pattern="[0-9]*"
          ref={dayRef}
          inputMode="numeric"
          id="day"
          value={value.Day || ""}
          placeholder="DD"
          className="w-1/3 border border-gray-300 rounded-none  py-3 px-2"
          onKeyDown={(e) => {
            if (
              e.keyCode !== 8 &&
              e.keyCode !== 13 &&
              e.keyCode !== 37 &&
              e.keyCode !== 39 &&
              e.keyCode !== 9 &&
              (e.keyCode < 48 || e.keyCode > 57)
            ) {
              e.preventDefault();
            }

            if (e.keyCode === 8) {
              if (dayRef.current.value.length === 0) {
                monthRef.current.focus();
              }
            }
          }}
          onChange={(e) => {
            if (parseInt(e.target.value) > 31) {
              e.target.value = "31";
            }
            if (
              (parseInt(value.Month) === 4 && parseInt(e.target.value) > 30) ||
              (parseInt(value.Month) === 6 && parseInt(e.target.value) > 30) ||
              (parseInt(value.Month) === 9 && parseInt(e.target.value) > 30) ||
              (parseInt(value.Month) === 11 && parseInt(e.target.value) > 30)
            ) {
              e.target.value = "30";
            }
            if (parseInt(value.Month) === 2 && parseInt(e.target.value) > 29) {
              e.target.value = "29";
            }
            handleOnChange(e.target.value, Day);

            if (e.target.value.length === 2) {
              setTimeout(() => {
                yearRef.current.focus();
              }, 0);
            }
          }}
          maxLength={2}
        />
        <input
          type="text"
          pattern="[0-9]*"
          ref={yearRef}
          inputMode="numeric"
          id="year"
          placeholder="YYYY"
          value={value.Year || ""}
          className="w-1/3 border border-gray-300 rounded-tr-md rounded-br-md py-3 px-2"
          onKeyDown={(e) => {
            if (
              e.keyCode !== 8 &&
              e.keyCode !== 13 &&
              e.keyCode !== 37 &&
              e.keyCode !== 39 &&
              e.keyCode !== 9 &&
              (e.keyCode < 48 || e.keyCode > 57)
            ) {
              e.preventDefault();
            }

            if (e.keyCode === 8) {
              if (yearRef.current.value.length === 0) {
                dayRef.current.focus();
              }
            }
          }}
          onChange={(e) => {
            if (parseInt(e.target.value) > new Date().getFullYear()) {
              e.target.value = new Date().getFullYear().toString();
            }
            handleOnChange(e.target.value, Year);
          }}
          maxLength={4}
        />
      </div>
      {error && <FieldError message={error} />}
    </div>
  );
};

export default DateSeparateInput;
