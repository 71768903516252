interface IStep {
  completed: boolean;
  step: number;
  active: boolean;
  title: string;
  currentStep: number;
  last: boolean;
  trigger?: React.RefObject<HTMLButtonElement>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}
const activeStatus = "border border-black ";
const inactiveStatus = "border border-gray-300";

const Step: React.FC<IStep> = ({
  completed,
  step,
  active,
  title,
  currentStep,
  last,
  trigger,
  setStep,
}) => {
  return (
    <div
      className="cursor-pointer min-h-[5rem] flex gap-4 max-lg:flex-col relative"
      onClick={() => {
        if (step - 1 > currentStep) {
          if (step === currentStep + 2) {
            trigger?.current?.click();
          }
          return;
        }
        setStep(step - 1);
      }}>
      <div className="flex flex-col max-lg:flex-row items-center max-lg:pb-8 max-sm:pb-6">
        <div
          className={`rounded-full w-10 h-10 max-lg:w-8 max-lg:h-8 flex items-center justify-center ${
            active ? activeStatus : inactiveStatus
          } ${completed && "bg-green-500 border-none"}`}>
          {completed ? (
            <svg
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1 6.86667L6.09091 12L17 1"
                stroke="white"
                strokeWidth="2.5"
              />
            </svg>
          ) : (
            <p className={`${active ? "text-black" : "text-gray-400"}`}>
              {step}
            </p>
          )}
        </div>
        {!last && (
          <div
            className={`h-10 w-[2px] max-lg:h-[2px] max-lg:w-16 max-sm:w-12 ${
              completed ? "bg-green-500" : "bg-gray-300"
            }`}></div>
        )}
      </div>
      <p
        className={`mt-2 font-semibold uppercase max-lg:top-10 max-lg:mt-0 max-lg:ml-1 max-lg:text-sm max-lg:absolute bottom-0 max-lg:left-[-16px] max-sm:text-[10px] max-sm:left-[-10px]  ${
          active ? "text-black" : "text-gray-400"
        }`}>
        {title}
      </p>
    </div>
  );
};

export default Step;
