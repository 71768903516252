import { useLazyQuery, useMutation } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useTimer } from "react-timer-hook";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import {
  ADD_SERVICE_CONSUMER,
  CONSUMER_EPISODE_START,
  CONSUMER_LOOK_UP,
  GET_CONSUMER_BY_ID,
  LINK_CONSUMER,
  UPDATE_CONSUMER_GUARDIAN,
  UPDATE_CONSUMER_IDENTITY_PROOF,
} from "../../apollo";
import { IConsumerEpisode, IServiceConsumer } from "../../interfaces";
import { loggerService, networkService } from "../../services";
import dateService from "../../services/date.service";
import websocketLoggerService from "../../services/websocket-logger";
import { useConsumerStore } from "../../store";
import useFormStore from "../../store/form/form.store";
import DateSeparateInput from "../shared/components/date-separate-input.component";
import FieldError from "../shared/components/field-error.component";
import FormikInput from "../shared/components/formik-input.component";
import GuideBanner from "../shared/components/guide-banner.component";
import UploadFile from "../shared/components/upload-file.component";
import { PatientFlowType, ScopeType, regexConstants } from "../shared/config";
import {
  DuplicatePatientCheckMessages,
  patientDetailsGuideBanner,
  patientDetailsQuestion,
} from "../shared/constants";
import { useServiceConsumer } from "../shared/hooks";
import { uploadTos3 } from "../shared/utils/file-upload";
import {
  isPatientCRUDFlow,
  parseDOB,
  parseISODateStr,
} from "../shared/utils/helper.util";

interface FirstTimePatientInfoFormProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;

  NextBtn: React.MutableRefObject<HTMLButtonElement>;
}

const FirstTimePatientInfoForm: React.FC<FirstTimePatientInfoFormProps> = ({
  setStep,

  NextBtn,
}) => {
  const [patientFlow, setPatientFlow, isValidDate] = useFormStore((store) => [
    store.patientFlow,
    store.setPatientFlow,
    store.isValidDate,
  ]);

  const [setCurrentFlowPatient, setIsGuardian] = useFormStore((store) => [
    store.setCurrentFlowPatient,
    store.setIsGuardian,
  ]);
  const time = new Date();
  time.setSeconds(time.getSeconds() + 100);

  const { seconds, minutes, isRunning, restart } = useTimer({
    expiryTimestamp: time,
  });

  const { user } = useAuth0();

  const serviceConsumer: any = useServiceConsumer(),
    [updateConsumer, setConsumerEpisode] = useConsumerStore((store) => [
      store.setConsumer,
      store.setConsumerEpisode,
    ]),
    [updateConsumerGuardian] = useMutation<{
      updateConsumer: IServiceConsumer;
    }>(UPDATE_CONSUMER_GUARDIAN),
    [updateConsumerDrivingLicense] = useMutation<{
      updateConsumer: IServiceConsumer;
    }>(UPDATE_CONSUMER_IDENTITY_PROOF),
    [fetchCurrentPatientDetails] = useLazyQuery<{
      consumerById: IServiceConsumer;
    }>(GET_CONSUMER_BY_ID);

  const [currentPatient, setCurrentPatient] = React.useState<any>(null);

  const fetchCurrentPatient = async (id) => {
    if (!id) return;
    try {
      const { data } = await fetchCurrentPatientDetails({
        variables: {
          consumer: {
            consumerId: id,
            organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
          },
        },
        context: {
          scope: ScopeType.Consumer,
        },
        fetchPolicy: "no-cache",
      });
      websocketLoggerService.sendMessage({
        eventType: "Returning Patient Form",
        eventSubType: "API Request fetchCurrentPatient success",
        eventData: `consumerId: ${id as string}`,
      });
      setCurrentPatient(data.consumerById);
    } catch (err) {
      websocketLoggerService.sendMessage({
        eventType: "Returning Patient Form",
        eventSubType: "API Request fetchCurrentPatient error",
        eventData: `consumerId: ${id as string} Error : ${JSON.stringify(err)}`,
      });
      loggerService.error("Error while fetching current patient", err);
    }
  };

  useEffect(() => {
    if (!serviceConsumer) return;
    fetchCurrentPatient(serviceConsumer?.id);
    setCurrentFlowPatient(serviceConsumer);
  }, [serviceConsumer, setCurrentFlowPatient]);

  const [addServiceConsumer] = useMutation<{
    addConsumer: IServiceConsumer;
  }>(ADD_SERVICE_CONSUMER);

  const [addConsumerEpisode] = useMutation<{
    createConsumerEpisode: IConsumerEpisode;
  }>(CONSUMER_EPISODE_START);

  const [consumerLookup] = useLazyQuery<{
    consumerLookUp: IServiceConsumer[];
  }>(CONSUMER_LOOK_UP, {
    context: {
      scope: ScopeType.Consumer,
    },
  });

  const [linkConsumer] = useMutation<{
    linkConsumers: IServiceConsumer;
  }>(LINK_CONSUMER);

  const [duplicatePatient, setDuplicatePatient] =
    React.useState<IServiceConsumer>(null);

  const formSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("First Name is required")
      .matches(regexConstants.firstNameRegExp, "Please enter a valid name."),
    lastName: Yup.string()
      .required("Last Name is required")
      .matches(regexConstants.lastNameRegExp, "Please enter a valid name."),
    email: Yup.string()
      .email("Please enter a valid email address.")
      .required("Please enter a valid email address"),
    birthDate: Yup.date()
      .typeError("Invalid Date")
      .required("Patient Date of Birth is required."),
    gender: Yup.string().required("Patient Sex assigned at birth is required."),
    mobileNumber: Yup.string()
      .required("Patient mobile phone number is required.")
      .matches(
        regexConstants.phoneRegExp,
        "Please enter a valid Patient Mobile Phone Number"
      ),
    homePhoneNumber: Yup.string().test(
      "isRequired",
      "Please enter a valid phone number",
      (val) => {
        if (!val || val?.length === 0) return true;
        else {
          return regexConstants.phoneRegExp.test(val);
        }
      }
    ),
    patientFrontLicense: Yup.string().required(
      "Patient front license required"
    ),
    otp: Yup.string().test("isRequired", "Invalid OTP", (val) => {
      if (duplicatePatient && val && val?.length > 6) return false;
      return true;
    }),
  });

  const checkDuplicate = async (values: any, setValues) => {
    try {
      const inputDate = values.birthDate;
      const parts = inputDate.split("/");
      const dateObject = `${parts[2] as string}-${parts[0] as string}-${
        parts[1] as string
      }`;

      const { data: duplicatePatient } = await consumerLookup({
        variables: {
          consumer: {
            organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            firstName: values.firstName,
            lastName: values.lastName,
            birthDate: dateObject,
            gender: values.gender,
          },
        },
      });

      if (duplicatePatient?.consumerLookUp?.length === 0) {
        websocketLoggerService.sendMessage({
          eventType: "Checking Duplicate Patient",
          eventSubType: "API Request consumerLookup success",
          eventData: `No data found for consumer: ${JSON.stringify(values)}`,
        });
        return false;
      } else if (duplicatePatient?.consumerLookUp?.length === 1) {
        setDuplicatePatient(duplicatePatient.consumerLookUp[0]);
        websocketLoggerService.sendMessage({
          eventType: "Checking Duplicate Patient",
          eventSubType: "API Request consumerLookup success",
          eventData: `one data found for consumer: ${JSON.stringify(values)}`,
        });
        if (duplicatePatient.consumerLookUp[0]?.mobileNumber === null) {
          setValues({
            ...values,
            verificationType: "option2",
          });
        }
        return true;
      } else if (duplicatePatient?.consumerLookUp?.length > 1) {
        setDuplicatePatient({} as IServiceConsumer);
        setValues({
          ...values,
          verificationType: "option2",
        });

        websocketLoggerService.sendMessage({
          eventType: "Checking Duplicate Patient",
          eventSubType: "API Request consumerLookup success",
          eventData: `More than one data found for consumer: ${JSON.stringify(
            values
          )}`,
        });
        return true;
      }
    } catch (error) {
      websocketLoggerService.sendMessage({
        eventType: "Checking Duplicate Patient",
        eventSubType: "API Request consumerLookup error",
        eventData: `Error: ${JSON.stringify(error)}`,
      });
      toast.error(error.message);
    }

    setDuplicatePatient(null);
    return false;
  };

  const handleSubmit = async (
    values,
    setSubmitting,
    setValues,
    setFieldValue
  ) => {
    try {
      if (
        ((serviceConsumer === null &&
          patientFlow === PatientFlowType.Yourself) ||
          patientFlow === PatientFlowType.Dependant) &&
        (await checkDuplicate(values, setValues))
      ) {
        setSubmitting(false);
        setTimeout(() => {
          const duplicatePatientSection = document.getElementById(
            "duplicatePatientSection"
          );
          duplicatePatientSection?.scrollIntoView({ behavior: "smooth" });
        }, 400);

        return;
      }

      const userTimezone = dateService.getUserTimezone();
      let currentServiceConsumer = serviceConsumer;

      if (!serviceConsumer && patientFlow !== PatientFlowType.Yourself) {
        const { data } = await addServiceConsumer({
          variables: {
            consumer: {
              email: user?.email || null,
              firstName: "",
              timeZone: userTimezone,
              mobileNumber: user?.phone_number || null,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            },
          },
          context: {
            scope: ScopeType.Consumer,
          },
        });
        const response = data.addConsumer;
        const serviceConsumerData = { ...response };
        if (!serviceConsumerData.timeZone) {
          serviceConsumerData.timeZone = userTimezone;
        }
        serviceConsumerData.isGuestUser = false;
        currentServiceConsumer = serviceConsumerData;
        updateConsumer(serviceConsumerData);
        const { data: episodeData } = await addConsumerEpisode({
          variables: {
            consumerEpisodeInput: {
              consumerId: serviceConsumerData?.id,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            },
          },
        });
        setConsumerEpisode({
          id: episodeData.createConsumerEpisode.id,
          isComplete: episodeData.createConsumerEpisode.isComplete,
        });
      }

      const {
        firstName,
        lastName,
        email,
        birthDate,
        gender,
        mobileNumber,
        homePhoneNumber,
        patientFrontLicense,
      }: {
        firstName: string;
        lastName: string;
        email: string;
        birthDate: string;
        gender: string;
        mobileNumber: string;
        homePhoneNumber: string;
        patientFrontLicense: string;
      } = values;

      // check if patientFrontLicense is base64 or not
      let uploadedImg = null;
      if (patientFrontLicense.startsWith("data:")) {
        uploadedImg = await uploadTos3(
          patientFrontLicense,
          `patientFrontLicense${uuid()}`
        );
      }

      if (patientFlow === PatientFlowType.Dependant) {
        setIsGuardian(true);
      }

      let payload: any = {
        birthDate: currentServiceConsumer?.birthDate
          ? currentServiceConsumer?.birthDate
          : null,
        firstName: currentServiceConsumer?.firstName,
        lastName: currentServiceConsumer?.lastName,
        email: currentServiceConsumer?.email,
        gender: currentServiceConsumer?.gender,
        mobileNumber: currentServiceConsumer?.mobileNumber?.slice(2),
        timeZone: currentServiceConsumer?.timeZone,
        id: currentServiceConsumer?.id,
        organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
        dependants: [
          {
            id: null,
            firstName,
            lastName,
            gender,
            mobileNumber,
            homePhoneNumber,
            email,
            birthDate: parseISODateStr(birthDate),
            timeZone: userTimezone,
            isPatient: true,
            organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
          },
        ],
      };

      if (patientFlow === PatientFlowType.Yourself) {
        payload = {
          id: currentServiceConsumer?.id || null,
          firstName,
          lastName,
          gender,
          mobileNumber,
          homePhoneNumber,
          email,
          birthDate: parseISODateStr(birthDate),
          timeZone: currentServiceConsumer?.timeZone || userTimezone,
          organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
          idProof: {
            ...currentServiceConsumer?.idProof,
            drivingLicenseFront: {
              name:
                uploadedImg?.fileName ||
                currentServiceConsumer?.idProof?.drivingLicenseFront?.name,
              cloudId:
                uploadedImg?.cloudId ||
                currentServiceConsumer?.idProof?.drivingLicenseFront?.cloudId,
              mimeType:
                uploadedImg?.mimeType ||
                currentServiceConsumer?.idProof?.drivingLicenseFront?.mimeType,
            },
          },
          insuranceDetails: {},
          dependants: [],
          isPatient: true,
        };
      }

      if (payload.mobileNumber) {
        payload.mobileNumber = `+1${payload.mobileNumber as string}`;
      }
      if (payload.homePhoneNumber && payload.homePhoneNumber !== "") {
        payload.homePhoneNumber = `+1${payload.homePhoneNumber as string}`;
      }

      if (
        patientFlow === PatientFlowType.Dependant &&
        payload.dependants[0].mobileNumber
      ) {
        payload.dependants[0].mobileNumber = `+1${String(
          payload.dependants[0].mobileNumber
        )}`;
      }
      if (
        patientFlow === PatientFlowType.Dependant &&
        payload.dependants[0].homePhoneNumber &&
        payload.dependants[0].homePhoneNumber !== ""
      ) {
        payload.dependants[0].homePhoneNumber = `+1${String(
          payload.dependants[0].homePhoneNumber
        )}`;
      }
      if (
        patientFlow === PatientFlowType.Dependant &&
        !payload.dependants[0].mobileNumber
      ) {
        toast.error("Please Provide Patient's Phone number.");
        return;
      }

      let data = null;

      if (patientFlow === PatientFlowType.Yourself && !currentServiceConsumer) {
        data = await addServiceConsumer({
          variables: {
            consumer: payload,
          },
          context: {
            scope: ScopeType.Consumer,
          },
        });
        data = data.data;
        data.consumer = data.addConsumer;
        const { data: episodeData } = await addConsumerEpisode({
          variables: {
            consumerEpisodeInput: {
              consumerId: data.consumer?.id,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            },
          },
        });
        setConsumerEpisode({
          id: episodeData.createConsumerEpisode.id,
          isComplete: episodeData.createConsumerEpisode.isComplete,
        });
        websocketLoggerService.sendMessage({
          eventType: "First Time Patient Info Form Submit - Add",
          eventSubType: "API: addServiceConsumer Success",
          eventData: `Patient data ${JSON.stringify(data?.consumer)}`,
        });
        updateConsumer(data.consumer);
      } else {
        data = await updateConsumerGuardian({
          variables: {
            consumer: payload,
          },
          context: {
            scope: ScopeType.Consumer,
          },
        });
        data = data.data;
        data.consumer = data.updateConsumer;
        updateConsumer({
          ...currentServiceConsumer,
          dependants: [...currentServiceConsumer?.dependants, data.consumer],
        });
        websocketLoggerService.sendMessage({
          eventType: "First Time Patient Info Form Submit - Update",
          eventSubType: "API: addServiceConsumer Success",
          eventData: `Patient data ${JSON.stringify(data?.consumer)}`,
        });
      }

      websocketLoggerService.sendMessage({
        eventType: "First Time Patient Info Form Submit",
        eventSubType: "API: updateConsumerGuardian Success",
        eventData: `Patient data ${JSON.stringify(data.consumer)}`,
      });

      if (patientFlow === PatientFlowType.Yourself) {
        updateConsumer(data.consumer);
      }

      let highestIdObj = null;
      let highestId = -Infinity;

      if (patientFlow === PatientFlowType.Dependant) {
        for (let i = 0; i < data.consumer.dependants.length; i++) {
          if (data.consumer.dependants[i]?.id > highestId) {
            highestId = data.consumer.dependants[i]?.id;
            highestIdObj = data.consumer.dependants[i];
          }
        }
        setCurrentFlowPatient(highestIdObj);
      } else {
        if (patientFlow === PatientFlowType.Yourself) {
          setCurrentFlowPatient(data.consumer);
        } else setCurrentFlowPatient(currentServiceConsumer);
      }

      if (patientFlow === PatientFlowType.Dependant) {
        await updateConsumerDrivingLicense({
          variables: {
            consumer: {
              ...highestIdObj,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
              idProof: {
                drivingLicenseFront: {
                  name: uploadedImg?.fileName,
                  cloudId: uploadedImg?.cloudId,
                  mimeType: uploadedImg?.mimeType,
                },
              },
            },
          },
          context: {
            scope: ScopeType.Consumer,
          },
        });

        websocketLoggerService.sendMessage({
          eventType: "First Time Patient Info Form Submit",
          eventSubType: "API: updateConsumerDrivingLicense Success",
          eventData: `Patient id ${highestIdObj?.id as string}`,
        });
      }
      setStep((prev) => prev + 1);
    } catch (err) {
      websocketLoggerService.sendMessage({
        eventType: "First Time Patient Info Form Submit",
        eventSubType: "Error in Submit Patient Details",
        eventData: `Error ${JSON.stringify(err)}`,
      });
      toast.error("Something went wrong. Please try again.");
      loggerService.error("PEDGuardian", err);
      setSubmitting(false);
    }
  };

  const handleSendOTP = async (values, setValues) => {
    try {
      const otpSend: any = await networkService.post(
        `${process.env.REACT_APP_OPEN_SCHEDULING_URL}/api/auth0-authentication/send-sms-otp`,
        {
          organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
          consumerId: duplicatePatient?.id,
        }
      );

      if (otpSend.error) {
        toast("Phone number is not valid");
        websocketLoggerService.sendMessage({
          eventType: "First Time Patient Info OTP Send",
          eventSubType: "API: sendSMSOTP Error",
          eventData: `Error ${JSON.stringify(otpSend?.error)}`,
        });
        return;
      }
      toast.success("OTP sent to patient's mobile number.");
      websocketLoggerService.sendMessage({
        eventType: "First Time Patient Info OTP Send",
        eventSubType: "API: sendSMSOTP Success",
        eventData: `Patient id ${duplicatePatient?.id as unknown as string}`,
      });
      const time = new Date();
      time.setSeconds(time.getSeconds() + 100);
      restart(time);

      setValues({
        ...values,
        otpSent: true,
      });
    } catch (error) {
      websocketLoggerService.sendMessage({
        eventType: "First Time Patient Info OTP Send",
        eventSubType: "API: sendSMSOTP Error",
        eventData: `Error ${JSON.stringify(error)}`,
      });
      console.log(error);
    }
  };
  const handleVerifyOTP = async (values, otpFromClient = false) => {
    // if service consumer is not present, create one
    // dependants - consumer
    try {
      const userTimezone = dateService.getUserTimezone();
      let currentServiceConsumer = serviceConsumer;
      if (!serviceConsumer && patientFlow !== PatientFlowType.Yourself) {
        const { data } = await addServiceConsumer({
          variables: {
            consumer: {
              email: user.email || null,
              firstName: "",
              timeZone: userTimezone,
              mobileNumber: user.phone_number || null,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            },
          },
          context: {
            scope: ScopeType.Consumer,
          },
        });
        const response = data.addConsumer;
        currentServiceConsumer = { ...response };
        if (!currentServiceConsumer.timeZone) {
          currentServiceConsumer.timeZone = userTimezone;
        }
        currentServiceConsumer.isGuestUser = false;
        updateConsumer(currentServiceConsumer);
        const { data: episodeData } = await addConsumerEpisode({
          variables: {
            consumerEpisodeInput: {
              consumerId: currentServiceConsumer.id,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            },
          },
        });
        setConsumerEpisode({
          id: episodeData.createConsumerEpisode.id,
          isComplete: episodeData.createConsumerEpisode.isComplete,
        });

        websocketLoggerService.sendMessage({
          eventType: "Creating Guardian for Dependant",
          eventSubType: "API: addServiceConsumer Success",
          eventData: `Patient id ${currentServiceConsumer?.id as string}`,
        });
      }

      // check if values.patientFrontLicense is base64 or not
      let uploadedImg = null;
      if (values.patientFrontLicense.startsWith("data:")) {
        uploadedImg = await uploadTos3(
          values.patientFrontLicense,
          `patientFrontLicense${uuid()}`
        );
      }

      const { data: consumer } = await linkConsumer({
        variables: {
          linkGuardianToConsumer: {
            otp: String(values.otp),
            guardianId:
              patientFlow === PatientFlowType.Yourself
                ? null
                : currentServiceConsumer.id,
            organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
            otpFromClient,
            isGuardianCase: patientFlow === PatientFlowType.Yourself,
            consumer: {
              id: duplicatePatient?.id || null,
              firstName: values.firstName,
              lastName: values.lastName,
              organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
              email: values.email,
              mobileNumber: `+1${values.mobileNumber as string}`,
              homePhoneNumber:
                values.homePhoneNumber !== ""
                  ? `+1${values.homePhoneNumber as string}`
                  : "",
              gender: values.gender,
              timeZone: userTimezone,
              birthDate: parseISODateStr(values.birthDate),
              idProof: {
                drivingLicenseFront: {
                  name: uploadedImg?.fileName,
                  cloudId: uploadedImg?.cloudId,
                  mimeType: uploadedImg?.mimeType,
                },
              },
            },
          },
        },
      });

      websocketLoggerService.sendMessage({
        eventType: "First Time Patient Info Link Consumer",
        eventSubType: "API: linkConsumer Success",
        eventData: `Patient id ${
          consumer?.linkConsumers?.id as unknown as string
        }`,
      });

      if (consumer.linkConsumers) {
        setCurrentFlowPatient(consumer.linkConsumers);
        if (patientFlow === PatientFlowType.Yourself) {
          updateConsumer(consumer.linkConsumers);
        } else {
          // update consumer dependants
          updateConsumer({
            ...currentServiceConsumer,
            dependants: [
              ...currentServiceConsumer?.dependants,
              consumer.linkConsumers,
            ],
          });
        }

        if (patientFlow === PatientFlowType.Yourself) {
          const { data: episodeData } = await addConsumerEpisode({
            variables: {
              consumerEpisodeInput: {
                consumerId: consumer.linkConsumers.id,
                organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
              },
            },
          });
          setConsumerEpisode({
            id: episodeData.createConsumerEpisode.id,
            isComplete: episodeData.createConsumerEpisode.isComplete,
          });
        }

        setStep(1);
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } catch (error) {
      websocketLoggerService.sendMessage({
        eventType: "First Time Patient Info Link Consumer",
        eventSubType: "API: linkConsumer Error",
        eventData: `Error ${JSON.stringify(error)}`,
      });
      console.log(error);
    }
  };

  function renderGuideBanner() {
    const pathnameToBannerMap = {
      "/consent/patient-onboarding-3": patientDetailsGuideBanner.onboarding3,
      "/consent/patient-onboarding-9": patientDetailsGuideBanner.onboarding9,
      "/consent/patient-onboarding-10": patientDetailsGuideBanner.onboarding10,
    };

    const bannerBody =
      pathnameToBannerMap[location.pathname] || patientDetailsGuideBanner.rest;

    return <GuideBanner body={bannerBody} />;
  }

  function renderQuestion() {
    const pathnameToQuestionMap = {
      "/consent/patient-onboarding-3": patientDetailsQuestion.onboarding3,
      "/consent/patient-onboarding-9": patientDetailsQuestion.onboarding9,
      "/consent/patient-onboarding-10": patientDetailsQuestion.onboarding10,
    };

    const question =
      pathnameToQuestionMap[location.pathname] || patientDetailsQuestion.rest;

    return <h1 className="font-bold text-3xl mt-4">{question}</h1>;
  }

  return (
    <div className="w-3/4 max-lg:w-full">
      <div className="px-4">
        {renderGuideBanner()}
        {renderQuestion()}
        <div className="mt-5">
          <form className="flex flex-col items-start w-full gap-x-3 justify-between max-lg:flex-col max-lg:items-start max-lg:gap-y-1">
            <div
              className={`flex items-center justify-start gap-2 mt-2 border p-4 font-bold text-md w-full rounded bg-primary/10 ${
                patientFlow === PatientFlowType.Yourself
                  ? "border-primary"
                  : "border"
              }`}>
              <input
                data-clarity-mask="True"
                onChange={() => {
                  setCurrentFlowPatient(serviceConsumer);
                  setPatientFlow(PatientFlowType.Yourself);
                  fetchCurrentPatient(serviceConsumer?.id);
                  setDuplicatePatient(null);
                }}
                type="radio"
                id={PatientFlowType.Yourself}
                name="patientFlow"
                checked={patientFlow === PatientFlowType.Yourself}
                value={PatientFlowType.Yourself}
              />
              <label className="w-full" htmlFor={PatientFlowType.Yourself}>
                You are the Patient {serviceConsumer?.firstName && " : "}
                {serviceConsumer?.firstName} {serviceConsumer?.lastName}
              </label>
            </div>
            {!isPatientCRUDFlow() && (
              <>
                <div className="relative flex py-2 items-center w-full">
                  <div className="flex-grow border-t border-[#D5D5D5]"></div>
                  <span className="flex-shrink mx-4 text-black">OR</span>
                  <div className="flex-grow border-t border-[#D5D5D5]"></div>
                </div>
                <div
                  className={`flex items-center justify-start gap-2 mt-2 border p-4 font-bold text-md w-full rounded bg-primary/10 ${
                    patientFlow === PatientFlowType.Dependant
                      ? "border-primary"
                      : "border"
                  }`}>
                  <input
                    data-clarity-mask="True"
                    onChange={() => {
                      setCurrentFlowPatient(null);
                      setPatientFlow(PatientFlowType.Dependant);
                      setDuplicatePatient(null);
                    }}
                    type="radio"
                    id={PatientFlowType.Dependant}
                    name="patientFlow"
                    checked={patientFlow === PatientFlowType.Dependant}
                    value={PatientFlowType.Dependant}
                  />
                  <label className="w-full" htmlFor="NewPatient">
                    <span className="font-normal">
                      You're completing the registration on behalf of the
                      patient &nbsp;: &nbsp;
                    </span>
                    New Patient
                  </label>
                </div>
              </>
            )}
          </form>
        </div>
        <hr className="my-6" />
        <main className="mt-6">
          {((isPatientCRUDFlow() &&
            ((patientFlow === PatientFlowType.Yourself &&
              !serviceConsumer?.isPatient) ||
              patientFlow === PatientFlowType.Dependant)) ||
            !isPatientCRUDFlow()) && (
            <>
              <Formik
                initialValues={{
                  id:
                    patientFlow === PatientFlowType.Yourself
                      ? serviceConsumer?.id || ""
                      : "",
                  firstName:
                    patientFlow === PatientFlowType.Yourself
                      ? serviceConsumer?.firstName || ""
                      : "",
                  lastName:
                    patientFlow === PatientFlowType.Yourself
                      ? serviceConsumer?.lastName || ""
                      : "",
                  email:
                    patientFlow === PatientFlowType.Yourself
                      ? (serviceConsumer?.email && serviceConsumer.email) ||
                        (user?.email !== "none" && user?.email) ||
                        ""
                      : "",
                  birthDate:
                    patientFlow === PatientFlowType.Yourself
                      ? (serviceConsumer?.birthDate &&
                          !serviceConsumer?.isGuestUser &&
                          parseDOB(serviceConsumer?.birthDate)) ||
                        null
                      : null,
                  gender:
                    patientFlow === PatientFlowType.Yourself
                      ? (!serviceConsumer?.isGuestUser &&
                          serviceConsumer?.gender !== "UNKNOWN" &&
                          serviceConsumer?.gender) ||
                        ""
                      : "",
                  mobileNumber:
                    patientFlow === PatientFlowType.Yourself
                      ? (!serviceConsumer?.isGuestUser &&
                          serviceConsumer?.mobileNumber?.slice(2)) ||
                        (user?.phone_number !== "none" &&
                          user?.phone_number?.slice(2)) ||
                        ""
                      : "",
                  homePhoneNumber:
                    patientFlow === PatientFlowType.Yourself
                      ? (!serviceConsumer?.isGuestUser &&
                          serviceConsumer?.homePhoneNumber?.slice(2)) ||
                        ""
                      : "",
                  patientFrontLicense:
                    patientFlow === PatientFlowType.Yourself
                      ? currentPatient?.idProof?.drivingLicenseFront
                          ?.cloudUrl || ""
                      : "",
                  patientFrontLicenseType:
                    patientFlow === PatientFlowType.Yourself
                      ? currentPatient?.idProof?.drivingLicenseFront
                          ?.mimeType || ""
                      : "",
                  verificationType: "",
                  otp: "",
                  otpSent: false,
                  care2uOtp: "",
                }}
                enableReinitialize
                validationSchema={formSchema}
                onSubmit={(
                  values,
                  { setSubmitting, setValues, setFieldValue }
                ) => {
                  handleSubmit(values, setSubmitting, setValues, setFieldValue);
                }}>
                {({
                  values,
                  initialValues,
                  isSubmitting,
                  dirty,
                  setValues,
                  setFieldValue,
                  setFieldError,
                  validateForm,
                  setTouched,
                }) => (
                  <Form className="flex flex-col gap-4">
                    <section className="flex flex-col gap-4">
                      <div>
                        <h1 className="font-bold text-3xl">
                          Review Patient Details{" "}
                          <p>{values.verificationType}</p>
                        </h1>
                        <div className="flex items-center mt-6 gap-10">
                          <p className="text-primary  uppercase text-sm ">
                            Patient Details
                          </p>
                          <div className="w-full h-[2px] bg-primary/60 flex-1"></div>
                        </div>
                      </div>
                      <div className="flex gap-6 items-start max-sm:flex-col max-sm:w-full max-sm:gap-3">
                        <FormikInput
                          name="firstName"
                          placeholder="Enter First Name"
                          type="text"
                          label="Patient First Name"
                          value={values.firstName}
                          onChange={(e) => {
                            setFieldValue("firstName", e.target.value.trim());
                          }}
                        />
                        <FormikInput
                          name="lastName"
                          placeholder="Enter Last Name"
                          type="text"
                          label="Patient Last Name"
                          value={values.lastName}
                          onChange={(e) => {
                            setFieldValue("lastName", e.target.value.trim());
                          }}
                        />
                      </div>

                      <FormikInput
                        name="email"
                        placeholder="Enter Email"
                        type="email"
                        disabled={
                          initialValues.email !== "" &&
                          initialValues.email !== "none" &&
                          !serviceConsumer?.isGuestUser
                        }
                        label="Patient Email"
                        value={values.email}
                      />

                      <div className="flex gap-6 items-start max-sm:flex-col max-sm:w-full max-sm:gap-3">
                        <FormikInput
                          name="birthDate"
                          placeholder="MM/DD/YYYY"
                          type="date"
                          label="Patient Date of Birth"
                          component={DateSeparateInput}
                          value={values.birthDate}
                        />

                        <FormikInput
                          name="gender"
                          placeholder="Select"
                          type="text"
                          label="Patient Sex Assigned at Birth"
                          value={values.gender}
                          component="select">
                          <option className="text-gray-400" value="" disabled>
                            Select
                          </option>
                          <option value="MALE">Male</option>
                          <option value="FEMALE">Female</option>
                        </FormikInput>
                      </div>

                      <FormikInput
                        name="mobileNumber"
                        placeholder="000 000 0000"
                        type="tel"
                        maxLength="10"
                        onInput={(e) =>
                          (e.target.value = e.target.value.replace(
                            /[^\d+]/g,
                            ""
                          ))
                        }
                        disabled={
                          !serviceConsumer?.isGuestUser &&
                          initialValues.mobileNumber !== ""
                        }
                        label="Patient Mobile Phone Number"
                        value={values.mobileNumber}
                      />

                      <FormikInput
                        name="homePhoneNumber"
                        placeholder="000 000 0000"
                        type="tel"
                        onInput={(e) =>
                          (e.target.value = e.target.value.replace(
                            /[^\d+]/g,
                            ""
                          ))
                        }
                        maxLength="10"
                        label="Patient Home Phone Number"
                        value={values.homePhoneNumber}
                      />
                    </section>
                    <div className="flex items-center mt-4 gap-10">
                      <p className="text-lg font-bold ">Identity Proof</p>
                    </div>
                    <div
                      className={`${!duplicatePatient ? "max-lg:pb-36" : ""}`}>
                      <label className="mb-2 text-sm font-semibold ">
                        Front of Patient's Driver's License
                        <span className="text-red-500 text-base">*</span>
                      </label>
                      <Field
                        type="file"
                        data-clarity-mask="True"
                        id="patientFrontLicense"
                        name="patientFrontLicense"
                        placeholder="Front of Patient's Driver's License"
                        component={UploadFile}
                        mimeType={values.patientFrontLicenseType}
                      />
                      <ErrorMessage name="patientFrontLicense">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                    </div>
                    <hr />

                    <div
                      className="max-lg:fixed max-lg:bottom-0 max-lg:left-0
                max-lg:px-5 bg-white max-lg:w-full ">
                      {dirty ||
                      (patientFlow === PatientFlowType.Yourself &&
                        (serviceConsumer?.isPatient === null ||
                          serviceConsumer?.isPatient === false)) ? (
                        <button
                          type="submit"
                          ref={NextBtn}
                          disabled={
                            !isValidDate || isSubmitting || !!duplicatePatient
                          }
                          className="w-full bg-primary px-20 max-sm:px-4 py-4 my-4 text-white rounded-md font-bold disabled:bg-gray-400 disabled:cursor-not-allowed max-lg:px-16">
                          {isSubmitting ? (
                            <div className="flex gap-2 items-center">
                              <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                              <div>Submitting...</div>
                            </div>
                          ) : window.location.pathname ===
                            "/consent/patient-onboarding-3" ? (
                            "Next Up : HIPLAC Consent"
                          ) : window.location.pathname ===
                            "/consent/patient-onboarding-9" ? (
                            "Next Up : CC Consent"
                          ) : window.location.pathname ===
                            "/consent/patient-onboarding-10" ? (
                            "Next Up : Attestation of no insurance"
                          ) : (
                            "Next Up : Additional Details"
                          )}
                        </button>
                      ) : (
                        <button
                          type="button"
                          ref={NextBtn}
                          disabled={
                            !isValidDate || isSubmitting || !!duplicatePatient
                          }
                          onClick={async () => {
                            const errors = await validateForm(values);
                            if (Object.keys(errors).length > 0) {
                              setTouched(
                                Object.keys(errors).reduce((acc, curr) => {
                                  acc[curr] = true;
                                  return acc;
                                }, {})
                              );
                              return;
                            }
                            setCurrentFlowPatient(currentPatient);
                            setStep((prev) => prev + 1);
                          }}
                          className="w-full bg-primary px-20 max-sm:px-4 py-4 my-4 text-white rounded-md font-bold disabled:bg-gray-400 disabled:cursor-not-allowed max-lg:px-16">
                          {isSubmitting ? (
                            <div className="flex gap-2 items-center">
                              <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                              <div>Submitting...</div>
                            </div>
                          ) : window.location.pathname ===
                            "/consent/patient-onboarding-3" ? (
                            "Next Up : HIPLAC Consent"
                          ) : window.location.pathname ===
                            "/consent/patient-onboarding-9" ? (
                            "Next Up : CC Consent"
                          ) : window.location.pathname ===
                            "/consent/patient-onboarding-10" ? (
                            "Next Up : Attestation of no insurance"
                          ) : (
                            "Next Up : Additional Details"
                          )}
                        </button>
                      )}
                    </div>
                    {duplicatePatient && (
                      <div
                        id="duplicatePatientSection"
                        className=" items-center my-6 gap-10 max-lg:pb-36">
                        <h1 className="text-2xl font-bold">
                          Looks like this is an existing patient
                        </h1>

                        {duplicatePatient?.id &&
                          duplicatePatient?.mobileNumber && (
                            <>
                              <div className="flex flex-col mt-4 text-lg">
                                <span className="p-4 bg-orange-light leading-7 rounded">
                                  If you have access to the registered patient
                                  phone{" "}
                                  {duplicatePatient?.mobileNumber &&
                                    "+1 *** ****" +
                                      duplicatePatient.mobileNumber.slice(
                                        -4
                                      )}{" "}
                                  then you can verify the patient identity and
                                  proceed using the first option (Option 1)
                                </span>
                                <div className="flex items-start justify-start gap-2 mt-4 p-2 border rounded">
                                  <input
                                    type="radio"
                                    name="verification-type"
                                    id=""
                                    checked={
                                      values.verificationType === "option1"
                                    }
                                    onChange={async () =>
                                      await setValues({
                                        ...values,
                                        verificationType: "option1",
                                      })
                                    }
                                    className="mt-2"
                                  />
                                  <label>
                                    <div className="flex flex-col gap-2">
                                      <span className="font-bold text-primary">
                                        Option 1:
                                      </span>
                                      <span className="font-bold">
                                        Send One Time Pass Code (OTP) on
                                        patient’s registered mobile number.{" "}
                                        {duplicatePatient?.mobileNumber &&
                                          "+1 *** ****" +
                                            duplicatePatient.mobileNumber.slice(
                                              -4
                                            )}{" "}
                                      </span>
                                      <span>
                                        OTP will be sent on patient’s registered
                                        mobile number{" "}
                                        {duplicatePatient?.mobileNumber &&
                                          "+1 *** ****" +
                                            duplicatePatient.mobileNumber.slice(
                                              -4
                                            )}{" "}
                                      </span>
                                    </div>
                                  </label>
                                </div>
                                {values.verificationType === "option1" && (
                                  <div className="p-4 bg-grey-light mt-4 rounded flex flex-col justify-start">
                                    <span className="text-xl font-bold">
                                      Verify Patient Phone Number Using One Time
                                      Pass Code
                                    </span>
                                    <span className="mt-2">
                                      We will send a verification code on
                                      patient’s previously registered mobile
                                      number{" "}
                                      {duplicatePatient?.mobileNumber &&
                                        "+1 *** ****" +
                                          duplicatePatient.mobileNumber.slice(
                                            -4
                                          )}{" "}
                                    </span>
                                    {!values.otpSent && (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          handleSendOTP(values, setValues);
                                        }}
                                        className="bg-primary px-10 mt-4 py-4 max-w-max rounded text-white">
                                        Send OTP
                                      </button>
                                    )}
                                    {/* Form to be shown when OTP is triggered */}
                                    {values.otpSent && (
                                      <>
                                        <div className="flex flex-col">
                                          <label
                                            htmlFor=""
                                            className="text-primary font-bold mt-4">
                                            Enter patient's One Time Pass Code
                                          </label>
                                          <div>
                                            <Field
                                              type="number"
                                              name="otp"
                                              onWheel={(e) => e.target.blur()}
                                              id="otp"
                                              maxLength="6"
                                              onChange={(e) => {
                                                if (isNaN(e.target.value))
                                                  return;
                                                setValues({
                                                  ...values,
                                                  otp: e.target.value,
                                                });
                                              }}
                                              placeholder="Enter patient's One Time Pass Code"
                                              className="rounded border border-primary py-4 w-3/4 mt-2 focus:border-1"
                                            />

                                            <ErrorMessage name="otp">
                                              {(msg) => (
                                                <FieldError message={msg} />
                                              )}
                                            </ErrorMessage>
                                          </div>
                                        </div>
                                        <button
                                          onClick={async () =>
                                            await handleSendOTP(
                                              values,
                                              setValues
                                            )
                                          }
                                          disabled={isRunning}
                                          className="mt-4 text-primary flex gap-2 items-center disabled:text-gray-400">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24">
                                            <g clipPath="url(#clip0_2556_73289)">
                                              <path
                                                fill={
                                                  isRunning ? "gray" : "#1E7FC2"
                                                }
                                                d="M5.463 4.433A9.961 9.961 0 0112 2c5.523 0 10 4.477 10 10 0 2.136-.67 4.116-1.81 5.74L17 12h3A8 8 0 006.46 6.228l-.997-1.795zm13.074 15.134A9.961 9.961 0 0112 22C6.477 22 2 17.523 2 12c0-2.136.67-4.116 1.81-5.74L7 12H4a8 8 0 0013.54 5.772l.997 1.795z"></path>
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_2556_73289">
                                                <path
                                                  fill="#fff"
                                                  d="M0 0H24V24H0z"></path>
                                              </clipPath>
                                            </defs>
                                          </svg>
                                          <div>
                                            <b>Resend Code</b>
                                            {isRunning &&
                                              `in ${
                                                minutes as unknown as string
                                              }:${
                                                seconds as unknown as string
                                              }`}
                                          </div>
                                        </button>
                                        <button
                                          type="button"
                                          onClick={async () => {
                                            if (values.otp)
                                              await handleVerifyOTP(values);
                                            else {
                                              setFieldError(
                                                "otp",
                                                "Please enter OTP"
                                              );
                                            }
                                          }}
                                          className="bg-primary px-10 mt-4 py-4 max-w-max rounded text-white">
                                          Verify To Proceed
                                        </button>
                                      </>
                                    )}
                                    <hr className="my-4" />
                                    <span>
                                      Previously registered patient phone number
                                      does not match ?
                                    </span>
                                    <span className="text-primary font-bold">
                                      Follow Option 2 below
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className=" flex py-5 items-center">
                                <div className="flex-grow border-t border-[#D5D5D5]"></div>
                                <span className="flex-shrink mx-4 text-black">
                                  OR
                                </span>
                                <div className="flex-grow border-t border-[#D5D5D5]"></div>
                              </div>
                            </>
                          )}
                        <div
                          className={`flex flex-col ${
                            duplicatePatient?.id &&
                            duplicatePatient?.mobileNumber
                              ? ""
                              : "mt-4"
                          }`}>
                          {duplicatePatient?.id &&
                          duplicatePatient?.mobileNumber ? (
                            <span className="p-4 bg-orange-light leading-7 rounded text-lg">
                              However if you don’t have access to the registered
                              patient phone number{" "}
                              {duplicatePatient?.mobileNumber &&
                                "+1 *** ****" +
                                  duplicatePatient.mobileNumber.slice(-4)}{" "}
                              or need to update the phone number or other
                              patient demographics please reach out to Care2U
                              Team on below phone number in Option 2
                            </span>
                          ) : duplicatePatient?.id ? (
                            <span className="p-4 bg-orange-light leading-7 rounded text-lg">
                              {
                                DuplicatePatientCheckMessages.singlePaitentWithoutMobileNumber
                              }
                            </span>
                          ) : (
                            <span className="p-4 bg-orange-light leading-7 rounded text-lg">
                              {DuplicatePatientCheckMessages.multiplePatients}
                            </span>
                          )}
                          <div
                            className={`flex items-start justify-start gap-2 mt-4 p-2 ${
                              duplicatePatient?.id &&
                              duplicatePatient?.mobileNumber
                                ? "border"
                                : ""
                            } rounded`}>
                            <input
                              type="radio"
                              name="verification-type"
                              checked={values.verificationType === "option2"}
                              id=""
                              onChange={async () =>
                                await setValues({
                                  ...values,
                                  verificationType: "option2",
                                })
                              }
                              className={`mt-1 ${
                                duplicatePatient?.id &&
                                duplicatePatient?.mobileNumber
                                  ? ""
                                  : "hidden"
                              }`}
                            />
                            <label>
                              <div className="flex flex-col gap-2 text-lg">
                                {duplicatePatient?.id &&
                                  duplicatePatient?.mobileNumber && (
                                    <p className="font-bold text-primary">
                                      Option 2:
                                    </p>
                                  )}
                                <p className="font-bold">
                                  Please call the Care2U team at +1 (877)
                                  247-5428
                                </p>
                                <p>
                                  Reach out to Care2u team for patient identity
                                  confirmation
                                </p>
                              </div>
                            </label>
                          </div>
                        </div>

                        {values.verificationType === "option2" && (
                          <div className="p-4 bg-grey-light mt-4 rounded flex flex-col justify-start">
                            <span className="text-xl font-bold">
                              Call Care2U Team for below details
                            </span>
                            <span className="mt-2 leading-7">
                              Please reach out to Care2U team member at
                              <b> +1 (877) 247-5428 </b> to get the one of the
                              below verification detail
                            </span>
                            <div className="flex flex-col">
                              <label
                                htmlFor=""
                                className="text-primary font-bold mt-4">
                                Enter 6 Digit One Time Code
                              </label>
                              <Field
                                type="number"
                                name="otp"
                                onWheel={(e) => e.target.blur()}
                                id="otp"
                                onChange={(e) => {
                                  if (isNaN(e.target.value)) return;
                                  setValues({
                                    ...values,
                                    otp: e.target.value,
                                  });
                                }}
                                maxLength="6"
                                placeholder="Enter patient's One Time Pass Code"
                                className="rounded border border-primary py-4 w-3/4 mt-2 focus:border-1"
                              />

                              <ErrorMessage name="otp">
                                {(msg) => <FieldError message={msg} />}
                              </ErrorMessage>
                            </div>
                            <button
                              type="button"
                              onClick={async () =>
                                await handleVerifyOTP(values, true)
                              }
                              className="bg-primary px-10 mt-4 py-4 max-w-max rounded text-white">
                              Verify to Proceed
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </>
          )}
          {!(
            (isPatientCRUDFlow() &&
              ((patientFlow === PatientFlowType.Yourself &&
                !serviceConsumer?.isPatient) ||
                patientFlow === PatientFlowType.Dependant)) ||
            !isPatientCRUDFlow()
          ) && (
            <div
              className="max-lg:fixed max-lg:bottom-0 max-lg:left-0
                max-lg:px-5 bg-white max-lg:w-full">
              <button
                type="button"
                ref={NextBtn}
                onClick={() => {
                  setCurrentFlowPatient(currentPatient);
                  setStep(1);
                }}
                className="w-full bg-primary px-20 py-4 my-4 text-white rounded-md font-bold disabled:bg-gray-400 disabled:cursor-not-allowed max-lg:px-16 ">
                {window.location.pathname === "/consent/patient-onboarding-3"
                  ? "Next Up : HIPLAC Consent"
                  : window.location.pathname === "/consent/patient-onboarding-9"
                  ? "Next Up : Credit Card Consent"
                  : window.location.pathname ===
                    "/consent/patient-onboarding-10"
                  ? "Next Up : Attestation of no insurance"
                  : "Next Up : Additional Details"}
              </button>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};

export default FirstTimePatientInfoForm;
