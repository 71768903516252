import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  OnboardPatientType,
  OnboardingStepType,
  OnboardingType,
} from "../../interfaces/onboarding.interface";
import websocketLoggerService from "../../services/websocket-logger";
import useFormStore from "../../store/form/form.store";
import AdditionalFormFields from "../additional-details/additional-details.component";
import { CcConsentForm } from "../cc-consent";
import InsuranceUpload from "../insurance-upload/insurance-upload.component";
import GuardianInfoForm from "../personal-info/pi-guardian-info-form.component";
import PersonalInfo from "../personal-info/pi-personal-info.component";
import TermsAndConditions from "../terms-and-conditions/terms-and-conditions.component";
import Step from "./components/step.component";

function getOnboardingStep(value: string): string {
  if (!value) return "";
  return value.replace("consent", "").replaceAll("/", "").toLowerCase();
}

const StepFlow = () => {
  const { pathname } = useLocation();
  const patientFlow = useFormStore((state) => state.patientFlow);
  const onboardingType = getOnboardingStep(pathname);
  const [haveInsurance, setHaveInsurance] = React.useState(true);
  const [ccCapturedInitiate, setCcCapturedInitiate] = React.useState(true);

  const [onboardingSteps, setOnboardingSteps] = React.useState([]);
  const [currentStep, setCurrentStep] = React.useState(
    parseInt(sessionStorage.getItem("step")) || 0
  );

  const patientInfoFormNextBtn = React.useRef(null);
  const guardianInfoFormNextBtn = React.useRef(null);
  const identityProofNextBtn = React.useRef(null);
  const ccCaptureFormNextBtn = React.useRef(null);

  const randomNumber = Math.floor(Math.random() * 100 + 1);

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.setItem("step", currentStep.toString());

    if (onboardingSteps[currentStep]?.title) {
      websocketLoggerService.sendMessage({
        eventType: "Step changed",
        eventSubType: "Step changed",
        eventData: `Step: ${JSON.stringify(
          onboardingSteps[currentStep].title
        )}`,
      });
    }
  }, [currentStep, onboardingSteps]);

  useEffect(() => {
    const onboardingSteps =
      patientFlow === OnboardPatientType.Self
        ? [
            {
              id: "patient",
              title: "Patient",
            },
            {
              id: "additional-details",
              title: "Additional Details",
            },
            {
              id: "insurance",
              title: "Insurance",
              trigger: identityProofNextBtn,
            },
            {
              id: "consent",
              title: "Consent",
              trigger: identityProofNextBtn,
            },
          ]
        : [
            {
              id: "patient",
              title: "Patient",
            },
            {
              id: "additional-details",
              title: "Additional Details",
            },
            {
              id: "guardian",
              title: "Health Care Proxy",
              trigger: patientInfoFormNextBtn,
            },
            {
              id: "insurance",
              title: "Insurance",
              trigger: guardianInfoFormNextBtn,
            },

            {
              id: "consent",
              title: "Consent",
              trigger: identityProofNextBtn,
            },
          ];

    if (haveInsurance) {
      const insuranceIndex = onboardingSteps.findIndex(
        (item) => item.id === "consent"
      );
      insuranceIndex !== -1 &&
        onboardingSteps.splice(insuranceIndex, 0, {
          id: "payment",
          title: "Payment",
          trigger: ccCaptureFormNextBtn,
        });
    }

    if (
      onboardingType === OnboardingType.Onboarding3 ||
      onboardingType === OnboardingType.Onboarding9 ||
      onboardingType === OnboardingType.Onboarding10
    ) {
      setOnboardingSteps(
        onboardingSteps.filter(
          (item) =>
            item.id !== "insurance" &&
            item.id !== "payment" &&
            item.id !== "guardian" &&
            item.id !== "additional-details"
        )
      );
    } else {
      setOnboardingSteps(onboardingSteps);
    }
  }, [patientFlow, haveInsurance, onboardingType]);

  const renderOnboardingStep = (value: number) => {
    const result = onboardingSteps[value];

    if (!result?.id) {
      return null;
    }

    const stepid = result.id;
    if (stepid === OnboardingStepType.Patient) {
      return (
        <PersonalInfo
          setStep={setCurrentStep}
          NextBtn={patientInfoFormNextBtn}
          key={1}
        />
      );
    } else if (stepid === OnboardingStepType.AdditionalFormFields) {
      return (
        <AdditionalFormFields
          key={3}
          setStep={setCurrentStep}
          NextBtn={identityProofNextBtn}
        />
      );
    } else if (stepid === OnboardingStepType.Guardian) {
      return (
        <GuardianInfoForm
          key={2}
          setStep={setCurrentStep}
          NextBtn={guardianInfoFormNextBtn}
        />
      );
    } else if (stepid === OnboardingStepType.Insurance) {
      return (
        <InsuranceUpload
          key={3}
          step={randomNumber}
          setStep={setCurrentStep}
          NextBtn={identityProofNextBtn}
          haveInsurance={haveInsurance}
          setHaveInsurance={setHaveInsurance}
        />
      );
    } else if (stepid === OnboardingStepType.Payment) {
      return (
        <CcConsentForm
          key={4}
          step={randomNumber}
          setStep={setCurrentStep}
          NextBtn={identityProofNextBtn}
          ccCapturedInitiate={ccCapturedInitiate}
          setCcCapuredInitiate={setCcCapturedInitiate}
        />
      );
    } else if (stepid === OnboardingStepType.Consent) {
      return (
        <TermsAndConditions
          haveInsurance={haveInsurance}
          key={5}
          setStep={setCurrentStep}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <div className="px-32 flex mt-8 gap-32 max-lg:flex-col max-lg:px-0 max-lg:gap-5">
      <div
        className={`w-1/4 flex flex-col ${
          onboardingSteps.length > 5
            ? "max-sm:justify-start max-lg:justify-center"
            : "max-lg:justify-center"
        }  gap-0 max-lg:flex-row max-lg:w-full max-lg:overflow-x-auto max-lg:px-4 max-lg:pb-2 `}>
        {onboardingSteps.map((item, index) => (
          <Step
            key={index}
            setStep={setCurrentStep}
            completed={currentStep > index}
            step={index + 1}
            currentStep={currentStep}
            last={index === onboardingSteps.length - 1}
            active={currentStep === index}
            title={item.title}
            trigger={item.trigger}
          />
        ))}
      </div>
      <hr className="h-px bg-gray-200 border-0 dark:bg-gray-700 lg:hidden" />
      <div className="w-full">{renderOnboardingStep(currentStep)}</div>
    </div>
  );
};

export default StepFlow;
