import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuthenticated, useServiceConsumer } from "../hooks";

const PrivateRoute: React.FC = () => {
  const location = useLocation();
  const isAuthenticated = useAuthenticated();
  const { isLoading, getAccessTokenSilently } = useAuth0();
  const serviceConsumer = useServiceConsumer();

  const [auth0User, setAuth0User] = React.useState<any>(null);

  React.useEffect(() => {
    const fetchUser = async () => {
      const accessToken = await getAccessTokenSilently();
      const { data: updatedUser } = await axios.get(
        `https://${process.env.REACT_APP_AUTH0_DOMAIN}/userinfo`,
        {
          headers: {
            authorization: `bearer ${accessToken}`,
          },
        }
      );
      setAuth0User(updatedUser);
    };

    if (!isLoading && isAuthenticated) fetchUser();
  }, [getAccessTokenSilently, isAuthenticated, isLoading]);

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (
    !isLoading &&
    auth0User &&
    !auth0User?.email_verified &&
    !serviceConsumer?.isGuestUser &&
    auth0User.email
  ) {
    const pathname: string = location.state?.from.pathname;
    if (pathname) {
      sessionStorage.setItem("redirectUrl", pathname);
    }
    return <Navigate to="/verifyemail" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;
