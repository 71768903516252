import produce from "immer";
import create, { State } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { PatientFlowType, storageTypes } from "../../app/shared/config";
import { IServiceConsumer } from "../../interfaces";

interface FormStore extends State {
  isGuardian: boolean;
  currentFlowPatient: IServiceConsumer;
  patientFlow: string;
  isValidDate: boolean;
  isCcConsentCaptured: boolean;
  guardianRelationship: string;
  setPatientFlow: (patientFlow: string) => void;
  setIsGuardian: (isGuardian: boolean) => void;
  setCurrentFlowPatient: (currentFlowPatient: IServiceConsumer) => void;
  setIsValidDate: (isValidDate: boolean) => void;
  setCcConsentCaptured: (isCcConsentCaptured: boolean) => void;
  setguardianRelationship: (patientYourselfRelationship: string) => void;
}

const useFormStore = create<FormStore>(
  persist(
    devtools(
      (set) => ({
        isGuardian: false,
        patientFlow: PatientFlowType.Yourself,
        isValidDate: true,
        currentFlowPatient: null,
        isCcConsentCaptured: false,
        guardianRelationship: null,
        setCurrentFlowPatient: (props) =>
          set(
            produce((state) => {
              state.currentFlowPatient = props;
            }),
            false,
            "formData/setCurrentFlowPatient"
          ),
        setIsGuardian: (props) =>
          set(
            produce((state) => {
              state.isGuardian = props;
            }),
            false,
            "formData/setIsGuardian"
          ),
        setPatientFlow: (props) =>
          set(
            produce((state) => {
              state.patientFlow = props;
            }),
            false,
            "formData/setPatientFlow"
          ),
        setIsValidDate: (props) =>
          set(
            produce((state) => {
              state.isValidDate = props;
            }),
            false,
            "formData/setIsValidDate"
          ),
        setCcConsentCaptured: (props) =>
          set(
            produce((state) => {
              state.isCcConsentCaptured = props;
            }),
            false,
            "formData/setCcConsentCaptured"
          ),
        setguardianRelationship: (props) =>
          set(
            produce((state) => {
              state.guardianRelationship = props;
            }),
            false,
            "formData/setguardianRelationship"
          ),
      }),
      { name: "formData", serialize: true }
    ),
    {
      name: "formData", // unique name
      getStorage: () => storageTypes.session, // (optional) by default, 'localStorage' is used
    }
  )
);

export default useFormStore;
