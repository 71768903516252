import produce from "immer";
import create, { State } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { storageTypes } from "../../app/shared/config";
import {
  IConsumerAppointment,
  IConsumerDependant,
  IConsumerEpisode,
  IConsumerStore,
  IServiceConsumer,
} from "../../interfaces";

interface ConsumerStore extends State {
  token: string;
  setConsumerToken: (x: string, exp?: number) => void;

  idTokenExpiry: number | null;

  authenticationId: string;
  setAuthenticationId: (x: string) => void;

  appointmentProps: IConsumerAppointment;
  setAppointmentProps: (x: IConsumerAppointment) => void;

  consumer: IServiceConsumer;
  setConsumer: (x: Partial<IServiceConsumer>) => void;

  dependant: IConsumerDependant;
  setDependant: (x: Partial<IConsumerDependant>) => void;

  consumerEpisode: IConsumerEpisode;
  setConsumerEpisode: (x: IConsumerEpisode) => void;

  setConsumerState: (x?: IConsumerStore) => void;
}

const useConsumerStore = create<ConsumerStore>(
  persist(
    devtools(
      (set) => ({
        token: null,
        idTokenExpiry: null,
        authenticationId: null,
        consumer: null,
        dependant: null,
        appointmentProps: null,
        consumerEpisode: null,
        setAppointmentProps: (props) =>
          set(
            produce((state) => {
              state.appointmentProps = props;
            }),
            false,
            "consumer/setConsumerEntity"
          ),
        setConsumer: (consumer) =>
          set(
            produce((state) => {
              state.consumer = consumer
                ? { ...state.consumer, ...consumer }
                : null;
            }),
            false,
            "consumer/setConsumer"
          ),
        setConsumerEpisode: (consumerEpisode) =>
          set(
            produce((state) => {
              state.consumerEpisode = consumerEpisode
                ? { ...state.consumerEpisode, ...consumerEpisode }
                : null;
            }),
            false,
            "consumer/setConsumerEpisode"
          ),
        setDependant: (dependant) =>
          set(
            produce((state) => {
              state.dependant = dependant
                ? { ...state.dependant, ...dependant }
                : null;
            }),
            false,
            "consumer/setDependant"
          ),
        setConsumerToken: (token, exp) =>
          set(
            produce((state) => {
              state.token = token;
              state.idTokenExpiry = exp;
            }),
            false,
            "consumer/setConsumerToken"
          ),
        setAuthenticationId: (authenticationId) =>
          set(
            produce((state) => {
              state.authenticationId = authenticationId;
            }),
            false,
            "consumer/setAuthenticationId"
          ),

        setConsumerState: (data) =>
          set(
            produce((state) => {
              state.token = data?.token || null;
              state.consumer = data?.consumer || null;
              state.dependant = data?.dependant || null;
              state.appointmentProps = data?.appointmentProps || null;
            }),
            false,
            "consumer/setConsumerState"
          ),
      }),
      { name: "consumer", serialize: true }
    ),
    {
      name: "consumer",
      getStorage: () => storageTypes.session,
    }
  )
);

export default useConsumerStore;
