import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import websocketLoggerService from "../../services/websocket-logger";
import { useConsumerStore } from "../../store";

const Logout: React.FC = () => {
  const setConsumerState = useConsumerStore((state) => state.setConsumerState);
  const { logout: Auth0Signout } = useAuth0();
  const consumer = useConsumerStore((state) => state.consumer);

  React.useEffect(() => {
    toast.success("Logged out successfully.");

    setConsumerState(null);
    sessionStorage.clear();

    websocketLoggerService.sendMessage({
      eventType: "LOGOUT",
      eventSubType: "LOGOUT",
      eventData: `user id: ${consumer?.id}`,
    });

    setTimeout(() => {
      Auth0Signout({
        returnTo: process.env.REACT_APP_LOGOUT_REDIRECT_URL,
      });
      localStorage.clear();
    }, 0);

    websocketLoggerService.sendMessage({
      eventType: "LOGOUT",
      eventSubType: "Navigating to login page",
      eventData: `user id: ${consumer?.id}`,
    });
  }, [setConsumerState, Auth0Signout]);

  return <Navigate to="/logout" />;
};

export default Logout;
