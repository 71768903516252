import { useMutation } from "@apollo/client";
import { ErrorMessage, Field, Form, Formik, FormikProps } from "formik";
import NProgress from "nprogress";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import { UPDATE_CONSUMER_IDENTITY_PROOF } from "../../apollo";
import { loggerService } from "../../services";
import websocketLoggerService from "../../services/websocket-logger";
import useFormStore from "../../store/form/form.store";
import FieldError from "../shared/components/field-error.component";
import GuideBanner from "../shared/components/guide-banner.component";
import UploadFile from "../shared/components/upload-file.component";
import { PatientFlowType, ScopeType } from "../shared/config";
import { uploadTos3 } from "../shared/utils/file-upload";

const PatientIdentityProofSchema = Yup.object().shape({
  InsuranceFrontSide: Yup.string().required("Required"),
  InsuranceBackSide: Yup.string(),
  secondaryInsuranceFrontSide: Yup.string(),
  secondaryInsuranceBackSide: Yup.string(),
});

interface InsuranceUploadProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step: number;
  NextBtn: React.RefObject<HTMLButtonElement>;
  haveInsurance: boolean;
  setHaveInsurance: (haveInsurance: boolean) => void;
}

const InsuranceUpload: React.FC<InsuranceUploadProps> = ({
  setStep,
  step,
  NextBtn,
  haveInsurance,
  setHaveInsurance,
}) => {
  const [currentFlowPatient, patientFlow, setCurrentFlowPatient] = useFormStore(
    (state) => [
      state.currentFlowPatient,
      state.patientFlow,
      state.setCurrentFlowPatient,
    ]
  );

  const [haveSecondaryInsurance, setHaveSecondaryInsurance] = useState(false);
  const [haveTertiaryInsurance, setHaveTertiaryInsurance] = useState(false);

  const [updateConsumerIdentityProof] = useMutation(
    UPDATE_CONSUMER_IDENTITY_PROOF
  );

  useEffect(() => {
    if (
      currentFlowPatient?.insuranceDetails?.secondaryFrontSide?.cloudUrl ||
      currentFlowPatient?.insuranceDetails?.secondaryBackSide?.cloudUrl
    ) {
      setHaveSecondaryInsurance(true);
    }
    if (
      currentFlowPatient?.insuranceDetails?.tertiaryFrontSide?.cloudUrl ||
      currentFlowPatient?.insuranceDetails?.tertiaryBackSide?.cloudUrl
    ) {
      setHaveTertiaryInsurance(true);
    }
  }, [
    currentFlowPatient?.insuranceDetails?.secondaryBackSide?.cloudUrl,
    currentFlowPatient?.insuranceDetails?.secondaryFrontSide?.cloudUrl,
  ]);

  function cloneWithoutCloudUrl(obj: any): any {
    if (typeof obj !== "object" || obj === null) {
      return obj; // Return primitive values and null as is
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => cloneWithoutCloudUrl(item));
    }

    const result = {};
    for (const key of Object.keys(obj)) {
      if (key === "cloudUrl") {
        continue; // Skip 'cloudUrl' properties
      }
      result[key] = cloneWithoutCloudUrl(obj[key]);
    }
    return result;
  }

  // Clone the insuranceDetails object excluding 'cloudUrl'

  const handleSubmit = async (values, setSubmitting) => {
    NProgress.start();
    // Increase randomly
    const interval = setInterval(function () {
      NProgress.inc();
    }, 500);

    try {
      const { idProof, insuranceDetails, ...details } = currentFlowPatient;

      const insuranceDetailsObj = cloneWithoutCloudUrl(insuranceDetails) || {};
      let needToMakeApiCall = false;

      if (values.InsuranceFrontSide.startsWith("data:")) {
        needToMakeApiCall = true;
        const insuranceFrontSideS3 = await uploadTos3(
          values.InsuranceFrontSide,
          `InsuranceFrontSide${uuid()}`,
          false
        );
        insuranceDetailsObj.frontSide = {
          name: insuranceFrontSideS3.fileName,
          cloudId: insuranceFrontSideS3.cloudId,
          mimeType: insuranceFrontSideS3.mimeType,
        };
      }

      if (values.InsuranceBackSide.startsWith("data:")) {
        needToMakeApiCall = true;
        const insuranceBackSideS3 = await uploadTos3(
          values.InsuranceBackSide,
          `InsuranceBackSide${uuid()}`,
          false
        );
        insuranceDetailsObj.backSide = {
          name: insuranceBackSideS3.fileName,
          cloudId: insuranceBackSideS3.cloudId,
          mimeType: insuranceBackSideS3.mimeType,
        };
      }

      if (values.secondaryFrontSide.startsWith("data:")) {
        needToMakeApiCall = true;
        const secondaryFrontSideS3 = await uploadTos3(
          values.secondaryFrontSide,
          `secondaryFrontSide${uuid()}`,
          false
        );
        insuranceDetailsObj.secondaryFrontSide = {
          name: secondaryFrontSideS3.fileName,
          cloudId: secondaryFrontSideS3.cloudId,
          mimeType: secondaryFrontSideS3.mimeType,
        };
      }

      if (values.secondaryBackSide.startsWith("data:")) {
        needToMakeApiCall = true;
        const secondaryBackSideS3 = await uploadTos3(
          values.secondaryBackSide,
          `secondaryBackSide${uuid()}`,
          false
        );
        insuranceDetailsObj.secondaryBackSide = {
          name: secondaryBackSideS3.fileName,
          cloudId: secondaryBackSideS3.cloudId,
          mimeType: secondaryBackSideS3.mimeType,
        };
      }
      if (values.tertiaryFrontSide.startsWith("data:")) {
        const tertiaryFrontSideS3 = await uploadTos3(
          values.tertiaryFrontSide,
          `tertiaryFrontSide${uuid()}`,
          false
        );
        insuranceDetailsObj.tertiaryFrontSide = {
          name: tertiaryFrontSideS3.fileName,
          cloudId: tertiaryFrontSideS3.cloudId,
          mimeType: tertiaryFrontSideS3.mimeType,
        };
      }

      if (values.tertiaryBackSide.startsWith("data:")) {
        const tertiaryBackSideS3 = await uploadTos3(
          values.tertiaryBackSide,
          `tertiaryBackSide${uuid()}`,
          false
        );
        insuranceDetailsObj.tertiaryBackSide = {
          name: tertiaryBackSideS3.fileName,
          cloudId: tertiaryBackSideS3.cloudId,
          mimeType: tertiaryBackSideS3.mimeType,
        };
      }

      if (!haveSecondaryInsurance) {
        insuranceDetailsObj.secondaryFrontSide = null;
        insuranceDetailsObj.secondaryBackSide = null;
      }
      if (!haveTertiaryInsurance) {
        insuranceDetailsObj.tertiaryFrontSide = null;
        insuranceDetailsObj.tertiaryBackSide = null;
      }

      if (needToMakeApiCall) {
        const updatedUser = await updateConsumerIdentityProof({
          variables: {
            consumer: {
              ...details,
              idProof: {},
              insuranceDetails: insuranceDetailsObj,
            },
          },
          context: {
            scope: ScopeType.Consumer,
          },
        });

        setCurrentFlowPatient(updatedUser.data.updateConsumer);

        websocketLoggerService.sendMessage({
          eventType: "PATIENT_INSURANCE_UPLOAD",
          eventSubType: "API Request: updateConsumerIdentityProof success",
          eventData: `ConsumerId : ${
            currentFlowPatient?.id as unknown as string
          }`,
        });
      }
      clearInterval(interval);
      NProgress.done();

      if (patientFlow === PatientFlowType.Yourself) {
        setStep((prevStep: number) => prevStep + 1);
      } else {
        setStep((prevStep: number) => prevStep + 1);
      }
    } catch (err) {
      websocketLoggerService.sendMessage({
        eventType: "PATIENT_INSURANCE_UPLOAD",
        eventSubType: "Error: updateConsumerIdentityProof",
        eventData: `Error : ${JSON.stringify(err)}`,
      });
      setSubmitting(false);
      toast.error("Something went wrong");
      loggerService.error(err);
      clearInterval(interval);
      NProgress.done();
    }
  };

  return (
    <div className="pb-10 px-4 w-3/4 max-lg:w-full">
      <div className="border border-gray-200 p-4 rounded-md mb-8 flex items-center justify-between gap-2">
        <div className="flex items-center gap-4 text-lg">
          <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="9.5" cy="10.2206" r="9.5" fill="#34A853" />
            <path
              d="M4.46875 10.7233L7.66928 13.8527L14.5276 7.14685"
              stroke="white"
              strokeWidth="1.5"
            />
          </svg>

          <p className="flex justify-between ">
            {currentFlowPatient?.firstName} {currentFlowPatient?.lastName}
          </p>
        </div>
        <span className="text-gray-400">Patient</span>
      </div>

      <h1 className="font-bold text-3xl mb-6">Patient Insurance Details</h1>

      <p>Does the patient have insurance ?</p>
      <form className="w-1/4 flex items-center gap-10 justify-between mb-6">
        <div className="flex items-center justify-start gap-2 mt-2">
          <input
            onChange={() => setHaveInsurance(true)}
            type="radio"
            id="yes"
            name="patientFlow"
            checked={haveInsurance}
            value="yes"
          />
          <label htmlFor="yes">Yes</label>
        </div>
        <div className="flex items-center justify-start gap-2 mt-2">
          <input
            onChange={() => setHaveInsurance(false)}
            type="radio"
            id="no"
            checked={!haveInsurance}
            name="patientFlow"
            value="no"
          />
          <label htmlFor="no">No </label>
        </div>
      </form>

      <hr className="mb-4" />

      {haveInsurance && (
        <div>
          <Formik
            initialValues={{
              InsuranceFrontSide:
                currentFlowPatient?.insuranceDetails?.frontSide?.cloudUrl || "",
              InsuranceFrontSideType:
                currentFlowPatient?.insuranceDetails?.frontSide?.mimeType || "",
              InsuranceBackSide:
                currentFlowPatient?.insuranceDetails?.backSide?.cloudUrl || "",
              InsuranceBackSideType:
                currentFlowPatient?.insuranceDetails?.backSide?.mimeType || "",
              secondaryFrontSide:
                currentFlowPatient?.insuranceDetails?.secondaryFrontSide
                  ?.cloudUrl || "",
              secondaryFrontSideType:
                currentFlowPatient?.insuranceDetails?.secondaryFrontSide
                  ?.mimeType || "",
              secondaryBackSide:
                currentFlowPatient?.insuranceDetails?.secondaryBackSide
                  ?.cloudUrl || "",
              secondaryBackSideType:
                currentFlowPatient?.insuranceDetails?.secondaryBackSide
                  ?.mimeType || "",

              tertiaryFrontSide:
                currentFlowPatient?.insuranceDetails?.tertiaryFrontSide
                  ?.cloudUrl || "",
              tertiaryFrontSideType:
                currentFlowPatient?.insuranceDetails?.tertiaryFrontSide
                  ?.mimeType || "",
              tertiaryBackSide:
                currentFlowPatient?.insuranceDetails?.tertiaryBackSide
                  ?.cloudUrl || "",
              tertiaryBackSideType:
                currentFlowPatient?.insuranceDetails?.tertiaryBackSide
                  ?.mimeType || "",
            }}
            enableReinitialize
            validationSchema={PatientIdentityProofSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleSubmit(values, setSubmitting);
            }}>
            {(props: FormikProps<any>) => (
              <Form className="flex flex-col items-start">
                <div className="w-full mt-4">
                  <h1 className="font-bold text-3xl mb-6">
                    Upload Patient's Primary Insurance Card Image
                  </h1>
                  <div>
                    <div>
                      <label className="mb-2 text-sm font-medium text-gray-600 ">
                        Front of the Insurance
                        <span className="text-red-500 text-base"> *</span>
                      </label>
                      <Field
                        type="file"
                        data-clarity-mask="True"
                        id="InsuranceFrontSide"
                        name="InsuranceFrontSide"
                        placeholder="Front of Insurance"
                        component={UploadFile}
                        mimeType={props.values.InsuranceFrontSideType}
                      />
                      <ErrorMessage name="InsuranceFrontSide">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                    </div>
                    <div className=" my-4">
                      <label className=" mb-2 text-sm font-medium text-gray-600 ">
                        Back of the Insurance
                      </label>
                      <Field
                        type="file"
                        data-clarity-mask="True"
                        id="InsuranceBackSide"
                        name="InsuranceBackSide"
                        placeholder="Front of Insurance"
                        component={UploadFile}
                        mimeType={props.values.InsuranceBackSideType}
                      />
                      <ErrorMessage name="InsuranceBackSide">
                        {(msg) => <FieldError message={msg} />}
                      </ErrorMessage>
                    </div>
                  </div>
                </div>

                <div className="flex items-center my-6 gap-10 w-full">
                  <p className="text-primary  uppercase text-sm ">
                    SECONDARY INSURANCE DETAILS
                  </p>
                  <div className="w-full h-[2px] bg-primary/60 flex-1"></div>
                </div>

                <p>Does the patient have secondary insurance ?</p>
                <div className="w-1/4 flex items-center gap-10 justify-between mb-6">
                  <div className="flex items-center justify-start gap-2 mt-2">
                    <input
                      onChange={() => setHaveSecondaryInsurance(true)}
                      type="radio"
                      id="yes"
                      name="secondaryInsurance"
                      checked={haveSecondaryInsurance}
                      value="yes"
                    />
                    <label htmlFor="yes">Yes</label>
                  </div>
                  <div className="flex items-center justify-start gap-2 mt-2">
                    <input
                      onChange={() => setHaveSecondaryInsurance(false)}
                      type="radio"
                      id="no"
                      checked={!haveSecondaryInsurance}
                      name="secondaryInsurance"
                      value="no"
                    />
                    <label htmlFor="no">No </label>
                  </div>
                </div>

                {haveSecondaryInsurance && (
                  <div className="w-full mt-4">
                    <h1 className="font-bold text-3xl mb-6">
                      Upload Secondary insurance card image
                    </h1>
                    <div>
                      <div>
                        <label className="mb-2 text-sm font-medium text-gray-600 ">
                          Front of the Insurance
                        </label>
                        <Field
                          type="file"
                          data-clarity-mask="True"
                          id="secondaryFrontSide"
                          name="secondaryFrontSide"
                          placeholder="Front of Insurance"
                          component={UploadFile}
                        />
                        <ErrorMessage name="secondaryFrontSide">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>
                      <div className=" my-4">
                        <label className=" mb-2 text-sm font-medium text-gray-600 ">
                          Back of the Insurance
                        </label>
                        <Field
                          type="file"
                          data-clarity-mask="True"
                          id="secondaryBackSide"
                          name="secondaryBackSide"
                          placeholder="Back of Insurance"
                          component={UploadFile}
                        />
                        <ErrorMessage name="secondaryBackSide">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                )}
                <p>Does the patient have tertiary insurance ?</p>
                <div className="w-1/4 flex items-center gap-10 justify-between mb-6">
                  <div className="flex items-center justify-start gap-2 mt-2">
                    <input
                      onChange={() => setHaveTertiaryInsurance(true)}
                      type="radio"
                      id="yes"
                      name="tertiaryInsurance"
                      checked={haveTertiaryInsurance}
                      value="yes"
                    />
                    <label htmlFor="yes">Yes</label>
                  </div>
                  <div className="flex items-center justify-start gap-2 mt-2">
                    <input
                      onChange={() => setHaveTertiaryInsurance(false)}
                      type="radio"
                      id="no"
                      checked={!haveTertiaryInsurance}
                      name="tertiaryInsurance"
                      value="no"
                    />
                    <label htmlFor="no">No </label>
                  </div>
                </div>

                {haveTertiaryInsurance && (
                  <div className="w-full mt-4">
                    <h1 className="font-bold text-3xl mb-6">
                      Upload Tertiary insurance card image
                    </h1>
                    <div>
                      <div>
                        <label className="mb-2 text-sm font-medium text-gray-600 ">
                          Front of the Insurance
                        </label>
                        <Field
                          type="file"
                          data-clarity-mask="True"
                          id="tertiaryFrontSide"
                          name="tertiaryFrontSide"
                          placeholder="Front of Insurance"
                          component={UploadFile}
                        />
                        <ErrorMessage name="tertiaryFrontSide">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>
                      <div className=" my-4">
                        <label className=" mb-2 text-sm font-medium text-gray-600 ">
                          Back of the Insurance
                        </label>
                        <Field
                          type="file"
                          data-clarity-mask="True"
                          id="tertiaryBackSide"
                          name="tertiaryBackSide"
                          placeholder="back of Insurance"
                          component={UploadFile}
                        />
                        <ErrorMessage name="tertiaryBackSide">
                          {(msg) => <FieldError message={msg} />}
                        </ErrorMessage>
                      </div>
                    </div>
                  </div>
                )}

                <div className="flex items-center gap-x-4 sticky bottom-0 sm:static bg-white w-full">
                  <button
                    onClick={() => {
                      if (patientFlow === PatientFlowType.Yourself) {
                        setStep((prev) => prev - 1);
                      } else {
                        setStep((prev) => prev - 1);
                      }
                    }}
                    className="border font-bold text-sm sm:text-base border-gray-300 rounded-md px-6 py-4"
                    type="button">
                    Back
                  </button>
                  <button
                    ref={NextBtn}
                    type="submit"
                    className="w-full bg-primary px-6 sm:px-10 text-sm sm:text-base py-4 my-4 text-white rounded-md font-bold">
                    {props.isSubmitting ? (
                      <div className="flex gap-2 items-center">
                        <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></div>
                        <div>Submitting...</div>
                      </div>
                    ) : (
                      "Next Up: Payment Consent"
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}

      {!haveInsurance && (
        <div className="flex flex-col items-start h-80">
          <GuideBanner body="I certify that I do not have any health insurance coverage and will be responsible to pay for services offered" />
          <div
            style={{
              marginBottom: "50vh",
            }}></div>
          <div className="flex items-center gap-4 sticky bottom-0 justify-start  w-full h-20 bg-white">
            <button
              onClick={() => {
                setStep((prev) => prev - 1);
              }}
              className="border font-bold border-gray-300 rounded-md px-6 py-4 my-4"
              type="button">
              Back
            </button>
            <button
              ref={NextBtn}
              onClick={() => {
                setStep((prev) => prev + 1);
              }}
              type="button"
              className="w-full bg-primary px-6 sm:px-12 text-sm sm:text-base py-4 my-4 text-white rounded-md font-bold">
              {"Next Up: Consent Sign"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default InsuranceUpload;
