import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GET_AGREEMENT_TEMPLATE } from "../../apollo";
import websocketLoggerService from "../../services/websocket-logger";
import useFormStore from "../../store/form/form.store";
import GuideBanner from "../shared/components/guide-banner.component";
import { PatientFlowType, ScopeType } from "../shared/config";
import TermsAndConditionCard from "../terms-and-conditions/terms-and-conditions-card.component";

interface CcConsentProps {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step: number;
  NextBtn: React.RefObject<HTMLButtonElement>;
  ccCapturedInitiate: boolean;
  setCcCapuredInitiate: (ccConsentCaptured: boolean) => void;
}

const CcConsentForm: React.FC<CcConsentProps> = ({
  setStep,
  step,
  NextBtn,
  ccCapturedInitiate,
  setCcCapuredInitiate,
}) => {
  const [isCcCaptured, setCcCaptured] = useFormStore((state) => [
    state.isCcConsentCaptured,
    state.setCcConsentCaptured,
  ]);
  const location = useLocation();
  const [TermsAndConditionAgreements, setTermsAndConditionAgreements] =
    useState([]);
  const [patientFlow] = useFormStore((state) => [state.patientFlow]);
  const [currentTemplate, setCurrentTemplate] = useState(0);

  const { data: queryResponse } = useQuery(GET_AGREEMENT_TEMPLATE, {
    variables: {
      agreementTemplateFilter: {
        organizationId: parseInt(process.env.REACT_APP_ORGANIZATION_ID),
      },
    },
    context: {
      scope: ScopeType.Consumer,
    },
  });

  websocketLoggerService.sendMessage({
    eventType: "CC_CONSENT",
    eventSubType: "API Request: GET_AGREEMENT_TEMPLATE",
    eventData: `org id: ${parseInt(process.env.REACT_APP_ORGANIZATION_ID)}`,
  });

  const [currentFlowPatient] = useFormStore((state) => [
    state.currentFlowPatient,
  ]);

  useEffect(() => {
    if (!queryResponse) return;
    const template = queryResponse?.getAgreementTemplate?.filter((data) => {
      return data.name === "Credit Card on File Authorization Form";
    });

    setTermsAndConditionAgreements(template);
  }, [location.pathname, queryResponse]);

  const handleIDisagreeBtn = () => {
    websocketLoggerService.sendMessage({
      eventType: "CC_CONSENT",
      eventSubType:
        "skipped consent or choose to Later over a phone call option",
      eventData: `Patient id - ${currentFlowPatient.id}`,
    });
    setCcCaptured(false);
    setCcCapuredInitiate(false);
    window.scrollTo(0, 0);
  };

  return (
    <div className="pb-24 px-4 w-3/4 max-lg:w-full">
      <div className="border border-gray-200 p-4 rounded-md mb-8 flex items-center justify-between gap-2">
        <div className="flex items-center gap-4 text-lg">
          <svg
            width="19"
            height="20"
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="9.5" cy="10.2206" r="9.5" fill="#34A853" />
            <path
              d="M4.46875 10.7233L7.66928 13.8527L14.5276 7.14685"
              stroke="white"
              strokeWidth="1.5"
            />
          </svg>

          <p className="flex justify-between capitalize ">
            {currentFlowPatient?.firstName} {currentFlowPatient?.lastName}
          </p>
        </div>
        <span className="text-gray-400">Patient</span>
      </div>

      <div
        className={`w-full bg-yellow-primary border border-[#FBBC05] px-6 py-3 mt-5 rounded-md`}>
        <p className="font-semibold ">Payment Information</p>
        <div className="text-sm pt-1">
          <ul className="list-disc pl-[0.8rem]">
            Please Note: Credit Card on file will be utilized for insured
            patients with responsibilities based upon the patient's health plan
            Deductible, Co-Insurance and/or Copayments.
            <p>
              *In addition, we will utilize CC on file for patients receiving
              out-of-pocket services such as Assisted Living Facility entrance
              fee with a remaining balance, if applicable.
            </p>
          </ul>
        </div>
      </div>

      <GuideBanner
        body="Disclaimer: CC on file will be used a maximum of 1 year from today for patient
          responsibilities and shall not exceed balances of $750. Consent for CC
          on File can be revoked by patient at any time."
      />

      <h1 className="font-bold my-6 flex items-center justify-between">
        <p className="text-lg">Add Consent for Credit Card on File</p>
        <span className="text-red-500 text-md font-normal">Optional*</span>
      </h1>

      <p>Do you consent to Credit Card on file?</p>
      <form className="flex flex-col mb-2">
        <div className="flex items-center justify-start gap-2 mt-2">
          <input
            onChange={() => setCcCapuredInitiate(true)}
            type="radio"
            id="yes"
            checked={ccCapturedInitiate}
            name="patientFlow"
            value="yes"
          />
          <label htmlFor="online">Yes</label>
        </div>
        <div className="flex items-center justify-start gap-2 mt-2">
          <input
            onChange={handleIDisagreeBtn}
            type="radio"
            id="later on phone call"
            checked={!ccCapturedInitiate}
            name="patientFlow"
            value="later on phone call"
          />
          <label htmlFor="later on phone call">Later over a phone call </label>
        </div>
      </form>

      {ccCapturedInitiate && (
        <div>
          <div id={`template-1`} className={`overflow-y-auto w-full`}>
            <div>
              <section className="flex flex-col flex-1 w-full mx-auto items-center">
                <div className="w-full mb-5">
                  {TermsAndConditionAgreements?.length ? (
                    <TermsAndConditionCard
                      key={0}
                      index={0}
                      patient={currentFlowPatient}
                      currentTemplate={currentTemplate}
                      setCurrentTemplate={setCurrentTemplate}
                      agreementData={TermsAndConditionAgreements[0]}
                      ccCapture={true}
                    />
                  ) : null}
                </div>

                <button
                  onClick={() => {
                    handleIDisagreeBtn();
                    if (patientFlow === PatientFlowType.Yourself) {
                      setStep((prevStep) => prevStep + 1);
                    } else {
                      setStep((prevStep) => prevStep + 1);
                    }
                  }}
                  className="underline text-gray-500 text-md lg:self-start mb-2">
                  Skip this step
                </button>
              </section>
            </div>
          </div>

          <div className="flex items-center gap-4 sticky bottom-0 justify-start  w-full h-20 bg-white">
            <button
              onClick={() => {
                if (patientFlow === PatientFlowType.Yourself) {
                  setStep((prev) => prev - 1);
                } else {
                  setStep((prev) => prev - 1);
                }
              }}
              className="border font-bold border-gray-300 rounded-md px-6 py-4 my-4"
              type="button">
              Back
            </button>
            <button
              ref={NextBtn}
              onClick={() => {
                if (patientFlow === PatientFlowType.Yourself) {
                  setStep((prev) => prev + 1);
                } else {
                  setStep((prev) => prev + 1);
                }
              }}
              type="button"
              disabled={!isCcCaptured}
              className={`w-full ${
                !isCcCaptured ? "bg-gray-400" : "bg-primary"
              } px-6 sm:px-12 text-sm sm:text-base py-4 my-4 text-white rounded-md font-bold`}>
              Next Up: Consent Sign
            </button>
          </div>
        </div>
      )}

      {!ccCapturedInitiate && (
        <div className="flex flex-col items-start h-80">
          <div
            style={{
              marginBottom: "30vh",
            }}></div>
          <div className="flex items-center gap-4 sticky bottom-0  justify-start  w-full h-20 bg-white">
            <button
              onClick={() => {
                if (patientFlow === PatientFlowType.Yourself) {
                  setStep((prev) => prev - 1);
                } else {
                  setStep((prev) => prev - 1);
                }
              }}
              className="border font-bold border-gray-300 rounded-md px-6 py-4 my-4"
              type="button">
              Back
            </button>
            <button
              ref={NextBtn}
              onClick={() => {
                if (patientFlow === PatientFlowType.Yourself) {
                  setStep((prev) => prev + 1);
                } else {
                  setStep((prev) => prev + 1);
                }
              }}
              type="button"
              className="w-full bg-primary px-6 sm:px-12 text-sm sm:text-base py-4 my-4 text-white rounded-md font-bold">
              Next Up: Consent Sign
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CcConsentForm;
